import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Badge, Button, Flex, Grid, Menu, MultiSelect, Pill, PillsInput, Popover, TagsInput, Text} from '@mantine/core';
import {useDisclosure} from "@mantine/hooks";
import {Spinner, useBreakpointValue, useToast} from "@chakra-ui/react";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {SearchLogo} from "../../assets/SearchIcon";
import {SimpleEpisodeCard} from "./episode-card";
import {ShowCardSkeleton} from "../skeletons";
import {useAddTagToEpisodes, useEpisodes} from "../../hooks/usePodcastLibraryQueries";
import {TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {RssLocalizationProgress} from "../progress-components";
import {AddIcon, ChevronLeftIcon, EditIcon} from "@chakra-ui/icons";
import {useGetTranscriptionSessions} from "../../hooks/useLocalizationQueries";
import {usePodcastLibrary} from "../../context/PodcastLibraryContext";
import {useNavigate} from "react-router-dom";

export enum LocalizationProgressFilter {
    ALL = 'All',
    DURATION = 'Duration',
    DATE = 'Date',
    TAGS = 'Tags',
    TRANSCRIPTION_COMPLETED = 'Transcription Completed',
    TRANSCRIPTION_IN_PROGRESS = 'Transcription In Progress',
    TRANSCRIPTION_NOT_STARTED = 'Transcription Not Started',
    TRANSLATION_COMPLETED = 'Translation Completed',
    TRANSLATION_IN_PROGRESS = 'Translation In Progress',
    TRANSLATION_NOT_STARTED = 'Translation Not Started',
    DUBBING_COMPLETED = 'Dubbing Completed',
    DUBBING_IN_PROGRESS = 'Dubbing In Progress',
    DUBBING_NOT_STARTED = 'Dubbing Not Started',
}

interface EpisodeModuleProps {
    podcastId: string;
}

export const EpisodeModule: React.FC<EpisodeModuleProps> = React.memo(({ podcastId }) => {
    const { data: podcastEpisodesData, isLoading: episodesLoading, error: episodeError } = useEpisodes(podcastId);
    const [progressFilter, setProgressFilter] = useState<LocalizationProgressFilter>(LocalizationProgressFilter.ALL);
    const [opened, { close, open }] = useDisclosure(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [tags, setTags] = useState<string[]>(['Test']);
    const [selectedTag, setSelectedTag] = useState<string | null>(null);
    const [episodesTags, setEpisodesTags] = useState<any>({});
    const [newTag, setNewTag] = useState('');
    const [selectedEpisodes, setSelectedEpisodes] = useState<string[]>([]);
    const {data: transcriptionSessions, isLoading: isLoadingTranscriptionSession, error: loadingError} = useGetTranscriptionSessions(podcastId)
    const { mutate: addTagToEpisodesReq, isSuccess: isAddTagToEpisodesSuccess,
        isError: isAddTagToEpisodesError, status: addTagToEpisodesStatus
    } = useAddTagToEpisodes();
    const toast: any = useToast()
    const {setLocalizationTasks} = usePodcastLibrary()
    const [sessionsMap, setSessionsMap] = useState<any>({})
    const navigate = useNavigate()
    const [nonSelectedEpisodes, setNonSelectedEpisodes] = useState<any>([])

    const dynamicPageSize = useBreakpointValue({ base: 2, md: 4, lg: 6, xl: 6 }) || 6;

    const episodes = useMemo(() => podcastEpisodesData || [], [podcastEpisodesData]);
    const sessions = useMemo(() => transcriptionSessions || [], [transcriptionSessions])


    useEffect(() => {
        if (episodes.length > 0) {
            let tagsData: any = {}

            for (const episode of episodes) {
                if (episode.tags) {
                    for (const tag of episode.tags) {
                        if (!tagsData[tag]) {
                            tagsData[tag] = 1;
                        } else {
                            tagsData[tag] += 1;
                        }
                    }
                }
            }
            setEpisodesTags(tagsData);
        }
    }, [episodes]);

    useEffect(() => {
        if (isAddTagToEpisodesSuccess) {
            toast({
                title: 'Episode Tags Added',
                description: 'Tags added successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setSelectedEpisodes([])
            setNewTag('');
            close();
        }

        if (isAddTagToEpisodesError) {
            toast({
                title: 'Error Adding Tags',
                description: 'There was an error adding tags to the episodes.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [isAddTagToEpisodesSuccess, isAddTagToEpisodesError]);

    useEffect(() => {
        if (sessions.length > 0 ) {
            let tasks: any[] = []
            let sMap: any = {}
            sessions.forEach((session: TranscriptionSession) => {
                sMap[session.id] = session
                switch (session.state) {
                    case TranscriptionState.PROOFREADING_COMPLETED: {
                        tasks.push(session)
                        break;
                    }
                    case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                        tasks.push(session.id)
                        break;
                    }
                }})
            setLocalizationTasks(tasks)
            setSessionsMap(sMap)
        }
    }, [sessions]);

    const handleSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    const handleAddNewTag = () => {
        const tagData = !selectedTag? newTag : selectedTag
        console.log(tagData)
        console.log(selectedEpisodes)
        if (tagData) {
            if (selectedTag) {
                addTagToEpisodesReq({episodes: selectedEpisodes, tag: tagData});
            } else if (!selectedTag && !episodesTags[tagData]) {
                addTagToEpisodesReq({episodes: selectedEpisodes, tag: tagData});
            }
        }
    }

    useEffect(() => {
        if (selectedTag) {
            let nSelectedEpisodes: any[] = []
            episodes.forEach((episode) => {
                const episodeTags = episode.tags? episode.tags : []
                if (!episodeTags.includes(selectedTag)) {
                    nSelectedEpisodes.push({ value: episode.id, label: episode.title })
                }
            })
            setNonSelectedEpisodes(nSelectedEpisodes)
        } else {
            setNonSelectedEpisodes([])
        }
    }, [selectedTag])

    const filteredEpisodes = useMemo(() => {
        return episodes.filter((episode) => {
            const { title, tags: episodeTags = [] } = episode;
            const normalizedSearchTerm = searchTerm.toLowerCase();
            const matchesSearch = title.toLowerCase().includes(normalizedSearchTerm);
            const matchesTag = !selectedTag || episodeTags.includes(selectedTag);

            let matchesProgressFilter = true;

            switch (progressFilter) {
                case LocalizationProgressFilter.ALL: {
                    return title.toLowerCase().includes(normalizedSearchTerm);
                }

                case LocalizationProgressFilter.TAGS: {
                    if (selectedTag) {
                        return episodeTags.includes(selectedTag);
                    }
                    break;
                }

                case LocalizationProgressFilter.TRANSCRIPTION_IN_PROGRESS: {
                    return episode.transcription.state !== TranscriptionState.NOT_CREATED && episode.transcription.state !== TranscriptionState.COMPLETED

                }
                case LocalizationProgressFilter.TRANSCRIPTION_COMPLETED:
                    return episode.transcription.state === TranscriptionState.COMPLETED;
                case LocalizationProgressFilter.TRANSCRIPTION_NOT_STARTED: {
                    return episode.transcription.state === TranscriptionState.NOT_CREATED;
                }
                case LocalizationProgressFilter.TRANSLATION_IN_PROGRESS: {
                    return episode.translation.processing.length > 0;
                }
                case LocalizationProgressFilter.TRANSLATION_COMPLETED: {
                    return episode.translation.processing.length === 0;
                }
                case LocalizationProgressFilter.TRANSLATION_NOT_STARTED: {
                    return episode.translation.processing.length === 0 && episode.translation.completed.length === 0;
                }

                case LocalizationProgressFilter.DUBBING_IN_PROGRESS: {
                    return episode.dubbing.processing.length > 0;
                }
                case LocalizationProgressFilter.DUBBING_COMPLETED: {
                    return episode.dubbing.processing.length === 0;
                }
                case LocalizationProgressFilter.DUBBING_NOT_STARTED: {
                    return episode.dubbing.processing.length === 0 && episode.dubbing.completed.length === 0;
                }
            }
        });
    }, [episodes, searchTerm, selectedTag, progressFilter]);

    if (episodesLoading || isLoadingTranscriptionSession) {
        return (
            <Grid w="100%" gutter="24px">
                {[...Array(dynamicPageSize)].map((_, index) => (
                    <Grid.Col span={6} key={index}>
                        <ShowCardSkeleton />
                    </Grid.Col>
                ))}
            </Grid>
        );
    }

    if (episodeError) {
        return <Text>Error loading episodes: {episodeError.message}</Text>;
    }

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} align={'center'} gap={'12px'}>
            <Flex w="100%" direction="row" align="center" justify="flex-start" gap="md" >
                <ChevronLeftIcon w="24px" h="24px" onClick={() => navigate('/')} cursor="pointer"/>
                <Flex align="center" gap="sm" w="100%">
                    <SearchLogo onType={handleSearchInputChange} setIsSearching={() => {return true}} onOpenWidth="40%" />
                </Flex>
            </Flex>
            <RssLocalizationProgress setProgressFilter={setProgressFilter}/>
            <Flex
                direction="column"
                align="flex-start"
                justify={'flex-start'}
                gap="md"
                p="12px"
                w="100%"
                h={'100%'}
                styles={{
                    root: {
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        borderBottom: '1px solid #E2E8F0',
                        padding: '10px 10px',
                    },

                }}
                style={{overflowY: 'auto' }}
            >
                <Text style={{ wordBreak: 'break-word', color: '#2C3E50', fontSize: '20px', fontWeight: 700, lineHeight: '24px' }}>
                    Library
                </Text>
                <Flex w="100%" direction="row" align="center" justify="space-between" gap="md">
                    <Flex w={'65%'} h={'100%'} align={'center'} justify={'space-between'} gap={'2px'}>
                        <Flex
                            p={'12px'}
                            styles={{
                                root: {
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    borderTopLeftRadius: 'var(--mantine-radius-xl)',
                                    borderBottomLeftRadius: 'var(--mantine-radius-xl)',
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    border: '1px solid var(--mantine-color-gray-4)',
                                    minWidth: 'fit-content',
                                    color: 'var(--mantine-color-gray-7)',
                                    whiteSpace: 'nowrap',
                                    '&:hover': {
                                        backgroundColor: 'var(--mantine-color-gray-7)',
                                    },
                                },
                            }}
                        >
                            <Text style={{fontSize: '14px', fontFamily: 'sans-serif', fontWeight: 600, lineHeight: '20px', color: '#2C3E50'}}>
                                Tags
                            </Text>
                        </Flex>
                        <Flex h={'100%'} align={'center'} w="100%" style={{ overflowX: 'auto', borderRadius: 'var(--mantine-radius-xl)',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        }}>
                            <PillsInput w={'100%'} size={'md'} variant={'unstyled'}>
                                <Pill.Group
                                    style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    paddingRight: '10px',
                                    alignItems: 'center',
                                    textColor: 'var(--mantine-color-gray-6)',
                                }}>
                                    {
                                        Object.entries(episodesTags).map(([key, value]) => (
                                            <Pill key={key}
                                                  onClick={(e: any) => {
                                                      if (selectedTag) {
                                                          if (selectedTag === key) {
                                                              setProgressFilter(LocalizationProgressFilter.ALL)
                                                              setSelectedTag(null);
                                                          } else {
                                                              setSelectedTag(key);
                                                              setProgressFilter(LocalizationProgressFilter.TAGS)
                                                          }
                                                      } else {
                                                          setSelectedTag(key);
                                                          setProgressFilter(LocalizationProgressFilter.TAGS)
                                                      }
                                                  }}
                                                  component={'button'}
                                                  style={{
                                                      height: '100%',
                                                      backgroundColor: selectedTag === key ? '#2D3748' : '#F7FAFC',
                                                      color: selectedTag === key ? '#FFFFFF' : '#4A5568',
                                                      borderRadius: 'var(--mantine-radius-xl)',
                                                      padding: '8px 12px',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                      fontFamily: 'sans-serif',
                                                      fontSize: '14px',
                                                      fontWeight: 500,
                                                      lineHeight: '20px',
                                                      fontStyle: 'normal',
                                                      whiteSpace: 'nowrap',
                                                      '&:hover': {
                                                          backgroundColor: 'var(--mantine-color-gray-7)',
                                                      },
                                                      '&:active': {
                                                          backgroundColor: '#2D3748',
                                                      },
                                                  }}
                                            >
                                                <Flex direction={'row'} align={'center'} justify={'center'} gap={'8px'}>
                                                    <Text>{`${key.charAt(0).toUpperCase() + key.slice(1)}`}</Text>
                                                    <Badge size={'sm'} circle>{`${value}`}</Badge>
                                                </Flex>
                                            </Pill>
                                        ))
                                    }
                                </Pill.Group>
                            </PillsInput>
                        </Flex>
                        <Flex
                            p={'14px'}
                            component={'button'}
                            styles={{
                                root: {
                                    alignItems: 'center',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopRightRadius: 'var(--mantine-radius-xl)',
                                    borderBottomRightRadius: 'var(--mantine-radius-xl)',
                                    minWidth: 'fit-content',
                                    color: 'var(--mantine-color-gray-7)',
                                    border: '1px solid var(--mantine-color-gray-4)',
                                    whiteSpace: 'nowrap',
                                    '&:hover': {
                                        backgroundColor: 'var(--mantine-color-gray-7)',
                                    },
                                },
                            }}
                        >
                            <Popover width={300} closeOnClickOutside={true} trapFocus={true} position="right" withArrow shadow="md">
                                {!selectedTag && <Popover.Target>
                                    <AddIcon w="14px" h="14px" onClick={() => open()}/>
                                </Popover.Target>
                                }
                                {selectedTag && <Popover.Target>
                                    <EditIcon w="14px" h="14px" onClick={() => open()}/>
                                </Popover.Target>
                                }
                                <Popover.Dropdown>
                                    <Flex direction="column" gap="md" styles={{root: {boxShadow: 'var(--mantine-shadow-md)'}}}>
                                        {!selectedTag && <TagsInput
                                            label="Enter tag name"
                                            placeholder="Enter tag"
                                            maxTags={1}
                                            value={newTag === '' ? [] : [newTag]}
                                            onOptionSubmit={(value: string) => {
                                                setNewTag(value)
                                            }}
                                        />
                                        }
                                        {selectedTag && <Badge variant="gradient" gradient={{from: 'teal', to: 'green', deg: 0}}>{selectedTag}</Badge>}
                                        <MultiSelect
                                            label={!selectedTag? 'Select episodes to add' : 'Add episodes to tag'}
                                            comboboxProps={{ withinPortal: false }}
                                            data={!selectedTag? episodes.map(episode => ({ value: episode.id, label: episode.title })) : nonSelectedEpisodes}
                                            value={selectedEpisodes}
                                            searchable
                                            onChange={setSelectedEpisodes}
                                            placeholder="Select episodes to tag"
                                            checkIconPosition="right"
                                            hidePickedOptions
                                        />
                                        {addTagToEpisodesStatus !== 'pending' && <Button onClick={handleAddNewTag}>Add Tag</Button>}
                                        {addTagToEpisodesStatus === 'pending' && <Spinner size={'sm'}/>}
                                    </Flex>
                                </Popover.Dropdown>
                            </Popover>
                        </Flex>
                    </Flex>
                </Flex>
                <Grid w="100%" h={'100%'} gutter="36px" style={{overflowY: 'auto' }}>
                    {filteredEpisodes.map((episode: PodcastEpisode) => (
                        <Grid.Col span={{ base: 12, sm: 6 }} key={`${episode.id}`}>
                            <SimpleEpisodeCard episode={episode}/>
                        </Grid.Col>
                    ))}
                </Grid>
            </Flex>
        </Flex>
    );
});
