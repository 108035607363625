import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Flex } from "@mantine/core";
import { usePodcastLibrary } from "../../context/PodcastLibraryContext";
import PodcastDashboard from "./Podcast-Dashboard";
import {usePodcasts} from "../../hooks/usePodcastLibraryQueries";
import {useUser} from "../../context/UserContext";

export enum LibraryMode {
    POST_PRODUCTION = 'post-production',
    PRODUCTION = 'production',
}

export default function PodcastLibrary() {
    const user: any = useUser()
    const { podcastId }: any = useParams();
    const { data: podcasts,  isLoading }: any = usePodcasts(user.user);

    const podcast = podcasts.find((p: any) => p.id === podcastId);

    return (
        <Flex w="full" style={{height: '100%', overflow: 'hidden'}}>
            {podcast && <PodcastDashboard />}
        </Flex>
    );
}
