import React, { useState } from 'react';
import { Popover, Pill, Button, Flex, Loader } from '@mantine/core';
import { randomColor } from "@chakra-ui/theme-tools";

const ModifiedPopoverPill = ({ tag, onRemoveTag, removeTagStatus }:
                                 {
                                     tag: string;
                                     onRemoveTag: (tag: string) => void;
                                     removeTagStatus: string;
                                 }) => {
    const [opened, setOpened] = useState(false);

    const handleOpen = () => setOpened(true);
    const handleClose = () => setOpened(false);

    const handleRemoveTag = (e: any) => {
        e.stopPropagation();
        onRemoveTag(tag);
        handleClose();
    };

    return (
        <Popover
            opened={opened}
            width={200}
            position="bottom"
            withArrow
            shadow="md"
            closeOnClickOutside={true}
            trapFocus={false}
            returnFocus={false}
        >
            <Popover.Target>
                <Pill
                    key={tag}
                    onClick={handleOpen}
                    color={randomColor({colors: ['indigo', 'blue', 'cyan', 'teal', 'lime', 'yellow', 'orange']})}
                    component={'button'}
                    style={{
                        height: '100%',
                        borderRadius: 'var(--mantine-radius-xl)',
                        padding: '8px 12px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'sans-serif',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        fontStyle: 'normal',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {`${tag.charAt(0).toUpperCase() + tag.slice(1)}`}
                </Pill>
            </Popover.Target>
            <Popover.Dropdown onClick={(e) => e.stopPropagation()}>
                {removeTagStatus === 'pending' ? (
                    <Flex justify={'center'} align={'center'}>
                        <Loader size={'sm'}/>
                    </Flex>
                ) : (
                    <Button w={'100%'} onClick={handleRemoveTag}>
                        Delete tag
                    </Button>
                )}
            </Popover.Dropdown>
        </Popover>
    );
};

export default ModifiedPopoverPill;
