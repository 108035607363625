import {useDisclosure, Progress} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import LibraryProvider from "../context/LibraryContext";
import LocalizationProvider from "../context/LocalizationContext";
import SimpleSidebar from "../components/publisher-drawer";
import {PublishersShell} from "../shells/publishers-shell";
import LibraryNavigationProvider from "../context/LibraryNavigationContext";
import {PodcastLibraryProvider} from "../context/PodcastLibraryContext";
import {PodcastLocalizationProvider} from "../context/PodcastLocalizationContext";
import {useUser} from "../context/UserContext";
import {UserCategory} from "../models/Users";

interface Page {
    name: string
    children: React.ReactNode | React.ReactNode[],
}

export default function PublisherLayout({name, children}: Page) {
    const user: any = useUser()
    const [isLoading, setIsLoading] = useState(true)

    return (
        <>
            {user.loading && <Progress size="xs" isIndeterminate />}
            {(!user.loading && user.user) && (
                <PodcastLibraryProvider>
                    <PodcastLocalizationProvider>
                        <LibraryProvider>
                            <LocalizationProvider>
                                <LibraryNavigationProvider>
                                    <PublishersShell children={children}/>
                                </LibraryNavigationProvider>
                            </LocalizationProvider>
                        </LibraryProvider>
                    </PodcastLocalizationProvider>
                </PodcastLibraryProvider>
            )}
        </>
    )
}
