import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Badge, Divider, Flex, Group, Image, Paper, Pill, PillsInput, Text} from '@mantine/core';
import {useUser} from "../../context/UserContext";
import {usePodcastLibrary} from "../../context/PodcastLibraryContext";
import {usePodcastLocalization} from "../../context/PodcastLocalizationContext";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {PaymentStatus} from "../../models/Cart";
import {PodcastTranscriptionTaskMeta, TranscriptionState} from "../../models/Localization";
import {secondsToHHMMSS, secondsToMinutes} from "../../utils/text-utils";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {useLocalization} from "../../context/LocalizationContext";
import {PodcastLibrarySidebarRoutes, useLibraryNavigation} from "../../context/LibraryNavigationContext";
import {useCart} from "../../hooks/useCartQueries";
import {Podcast} from "../../models/Podcast";
import {LocalizationProgressFilter} from "./episode-module";

export const EpisodeCard = ({
                                episode,
                                displayCheck,
                                isInMultiConfiguration}: {
    episode: PodcastEpisode,
    displayCheck: "hidden" | "visible",
    isInMultiConfiguration?: boolean
}) => {
    const user: any = useUser();
    const { isEpisodeInCart, cart } = usePodcastLibrary();
    const { episodesConfiguration, setEpisodesConfiguration } = usePodcastLocalization();
    const [isInCart, setIsInCart] = useState<boolean>(false);
    const [waitingServerConfirmation, setWaitingServerConfirmation] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const navigate = useNavigate();
    const { podcastId } = useParams();
    const {currentPodcast} = usePodcastLibrary();
    const [hasBeenProcessed, setHasBeenProcessed] = useState<boolean>(false);

    useEffect(() => {
        if (episode.transcription.state !== TranscriptionState.NOT_CREATED) {
            setHasBeenProcessed(true);
        }
    }, [episode]);

    useEffect(() => {
        setIsInCart(isEpisodeInCart(episode.id));
        const cartItem = cart.find(item => item.episode.id === episode.id);
        setWaitingServerConfirmation(cartItem?.status === PaymentStatus.CHECKED_OUT);
    }, [cart, episode.id, isEpisodeInCart]);

    useEffect(() => {
        setIsChecked(isInMultiConfiguration || episodesConfiguration.has(episode.id));
    }, [isInMultiConfiguration, episodesConfiguration, episode.id]);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newConfiguration = new Map(episodesConfiguration);
        if (e.target.checked) {
            newConfiguration.set(episode.id, { episode });
        } else {
            newConfiguration.delete(episode.id);
        }
        setEpisodesConfiguration(newConfiguration);
    };

    const goToAiTranscript = () => {
        let sessionId = episode.transcription.session?.id;
        if (sessionId) {
            navigate(`/reader/${sessionId}/asr`);
        }
    };

    const LocalizationStatusCard = () => (
        <Flex w={'100%'} justify="space-between" align={'center'}>
            <Flex w={'100%'} p={'md'} direction="column" align="center" justify={'center'}>
                <Text c="gray" fw={700}>Transcription</Text>
                <Badge size="sm" variant="gradient" gradient={{ from: 'teal', to: 'green', deg: 0 }}>
                    {episode.transcription.state}
                </Badge>
            </Flex>
            <Divider orientation={'vertical'} />
            <Flex w={'100%'} p={'md'} direction="column" align="center" justify={'center'}>
                <Text c="gray" fw={700}>Translation</Text>
                <Badge size="sm" variant="gradient" gradient={{ from: 'teal', to: 'green', deg: 0 }}>
                    Not started
                </Badge>
            </Flex>
            <Divider orientation={'vertical'} />
            <Flex w={'100%'} p={'md'} direction="column" align="center" justify={'center'}>
                <Text c="gray" fw={700}>Dubbing</Text>
                <Badge size="sm" variant="gradient" gradient={{ from: 'teal', to: 'green', deg: 0 }}>
                    Not started
                </Badge>
            </Flex>
        </Flex>
    );

    return (
        <Flex direction="column" align="flex-start" gap="56px" style={{ alignSelf: 'stretch' }}>
            <Paper w={'100%'} radius="md" component="button" shadow={'sm'} withBorder onClick={goToAiTranscript}>
                <Flex align={'flex-start'} p={'md'} gap={'md'} direction={'column'}>
                    <Flex align={'flex-start'} gap={'md'} direction={'row'} justify={'flex-start'}>
                        <Image src={episode.image_url} radius="md" w={'100px'} h={'100px'}/>
                        <Group gap="2px" style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                            <Text c="gray.6" fz="sm" fw={600} style={{textAlign: 'left'}}>
                                {episode.title}
                            </Text>
                            <Text c="gray.7" fz="sm">
                                {secondsToHHMMSS(episode.duration)}
                            </Text>
                            {displayCheck === 'visible' && (
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            )}
                            {isInCart && <Badge color="green">In Cart</Badge>}
                            {waitingServerConfirmation && <Badge color="yellow">Processing</Badge>}
                            {hasBeenProcessed && <Badge color="blue">Processed</Badge>}
                        </Group>
                    </Flex>
                    {hasBeenProcessed && <LocalizationStatusCard />}
                </Flex>
            </Paper>
        </Flex>
    );
};

export const SimpleEpisodeCard = ({ episode}: { episode: PodcastEpisode }) => {
    const libraryNavigation: any = useLibraryNavigation();
    const {podcasts} = usePodcastLibrary()
    const {podcastId} = useParams()
    const [currentPodcast, setCurrentPodcast] = useState<Podcast>();
    const [isInCart, setIsInCart] = useState<boolean>(false);
    const localization: any = useLocalization()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { data: cartData = [] , isLoading: cartLoading, isError: cartError} = useCart()
    const [isItemInCart, setIsItemInCart] = useState<boolean>(false);
    const toast = useToast()
    const [session, setSession] = useState<any>(null);
    const navigate = useNavigate()


    useEffect(() => {
        if (!cartLoading && cartData.length > 0) {
            const itemInCart = cartData.find((item: any) => item.episode.id === episode.id);
            if (itemInCart) {
                setIsItemInCart(true);
            } else {
                setIsItemInCart(false);
            }
        }
    }, [cartLoading, cartData]);

    useEffect(() => {
        if (podcasts.length > 0) {
            const podcast = podcasts.find((p: any) => p.id === podcastId);
            setCurrentPodcast(podcast);
        }
    }, [podcasts]);

    const handleOpenEpisodeSidebar = () => {
        if (!isItemInCart && episode.transcription.state === TranscriptionState.NOT_CREATED) {
            if (libraryNavigation.displayLibrarySidebar) {
                libraryNavigation.setRoute(PodcastLibrarySidebarRoutes.EPISODES)
                libraryNavigation.setCurrentEpisode(episode);
            } else {
                libraryNavigation.setRoute(PodcastLibrarySidebarRoutes.EPISODES)
                libraryNavigation.setCurrentEpisode(episode);
                libraryNavigation.setDisplaySidebar(true);
            }

        } else if (isItemInCart) {
            toast({
                title: 'Episode in Cart',
                description: 'The episode is already in your cart.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    const getNavigationText = () => {
        switch (session.state) {
            case TranscriptionState.SPEECH_TO_TEXT: {
                break
            }
            case TranscriptionState.PROOFREADING: {
                return 'Open AI Transcript'
            }
            case TranscriptionState.PROOFREADING_COMPLETED: {
                return 'Open Proofread Transcript'
            }

            case TranscriptionState.READY_FOR_PROOFREADING: {
                return 'Open AI Transcript'

            }
            case TranscriptionState.EDITOR_REVIEWING: {
                return 'Open Proofread Transcript'
            }
            case TranscriptionState.EDITOR_REVIEW: {
                return 'Open Proofread Transcript'
            }

            case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                return 'Start Final Review'
            }
            case TranscriptionState.PUBLISHER_REVIEWING: {
                return 'Resume Final Review'
            }
            case TranscriptionState.COMPLETED: {
                return 'Open Final Transcript'
            }

        }
    }

    const SpeakersTag = () => {
        return (
            <Flex w={'100%'} direction="row" gap={'md'} style={{ overflowX: 'auto', borderRadius: 'var(--mantine-radius-xl)',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            }}>
                {episode.speakers.map((speaker: any) => (
                    <Flex w={'100%'} direction="row" gap={'md'}>
                        <Badge color="blue" variant="light">
                            {speaker.name}
                        </Badge>
                    </Flex>
                ))}
            </Flex>
        )
    }

    return (
        <Flex direction="column" h={'100%'} align="flex-start" justify={'flex-start'} gap="56px" style={{ alignSelf: 'stretch' }} onClick={() => {
            navigate(`/library/${podcastId}/${episode.id}`)
        }}>
            <Paper w={'100%'} h={'100%'} radius="md" component="button" shadow={'sm'} withBorder>
                <Flex align={'flex-start'} p={'md'} gap={'md'} direction={'row'}>
                    <Image src={episode.image_url ? episode.image_url : currentPodcast?.image_url} radius="md" w={'100px'} h={'100px'}/>
                    <Flex direction="column" style={{ flex: 1, minWidth: 0, overflow: 'hidden' }}>
                        <Text
                            c="gray.7"
                            fz="md"
                            fw={600}
                            style={{
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                                marginBottom: '4px'
                            }}
                            title={episode.title}
                        >
                            {episode.title}
                        </Text>
                        <Flex w={'100%'} direction={'column'} align="flex-start" justify={'flex-start'} wrap="wrap" gap="sm">
                            <Text c="gray.6" fz="sm" fw={600}>
                                {new Date(episode.pubDate).toISOString().split('T')[0]}
                            </Text>
                            <Text c="gray.5" fz="sm" fw={600}>
                                {secondsToMinutes(episode.duration)}
                            </Text>
                            <SpeakersTag/>
                            {isItemInCart && (
                                <Badge size="sm" color="orange">
                                    In Cart
                                </Badge>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Paper>
        </Flex>
    );
};
