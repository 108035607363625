import {buildStyles} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import {EpisodesIcon} from "../../assets/EpisodesIcon";
import React, {useEffect, useState} from "react";
import {Podcast} from "../../models/Podcast";
import {removePTags} from "../../utils/text-utils";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {useLibraryNavigation} from "../../context/LibraryNavigationContext";
import {YoutubeChannelInfoModule} from "../youtube/youtube-channel-module";
import {Flex, Group, Image, Paper, Text} from '@mantine/core';
import {useToast} from "@chakra-ui/react";


export default function PodcastModule({podcast, setContent, links}: {podcast: Podcast, setContent?: any, links?: any}) {
    const user: any = useUser()
    const libraryNavigation: any = useLibraryNavigation()
    const [tabIndex, setTabIndex] = useState<number>(0)
    const [episodeLoading, setEpisodeLoading] = useState<boolean>(true)
    const [hasEpisodes, setHasEpisodes] = useState<boolean>(false)
    const [languagesProgress, setLanguagesProgress] = useState<any>({})
    const [episodeTranscriptionProgress, setEpisodeTranscriptionProgress] = useState<any>({})
    const [currentLanguage, setCurrentLanguage] = useState<string>()
    const [speakers, setSpeakers] = useState<any>([])
    const toast: any = useToast()

    const episodesProgressReq = useApi(ENDPOINTS.PUBLISHER_GET_EPISODES_PROGRESS, user.token, false)

    //const speakersReq = useApi(`/publisher/speakers/${podcast.id}`, user.token, true)

    useEffect(() => {
        if (podcast) {
            libraryNavigation.setCurrentPodcast(podcast)
        }
    }, [podcast]);

    useEffect(() => {
        if (!episodesProgressReq.loading && episodesProgressReq.data) {
            setLanguagesProgress(episodesProgressReq.data.progress)
            setEpisodeTranscriptionProgress(episodesProgressReq.data.transcriptionProgress)
            setEpisodeLoading(false)
        }
    }, [episodesProgressReq.loading])

    const styles = buildStyles({
        pathColor: '#38B2AC',
        textColor: '#2D3748',
        trailColor: '#E2E8F0',
    })

    const handleLanguageChange = () => {
        if (Object.keys(languagesProgress).length === 0) {
            toast({
                title: "No languages available",
                description: "You have not yet localized an episode for this podcast",
                status: "warning",
                duration: 3000,
            })
        }
    }

    return (
        <Flex direction="column" align="flex-start" gap="56px" style={{ alignSelf: 'stretch' }}>
            <Paper w={'100%'} withBorder p="md" radius="md" >
                <Flex align={'flex-start'} gap={'md'} direction={'row'}>
                    <Image src={podcast.image_url} radius="md" w={'100px'} h={'100px'} />
                    <Flex direction="column" gap="xs" style={{ flex: '1 0 0' }}>
                        <Text c="gray.8" fz="md" fw={600} lineClamp={1}>
                            {podcast.title}
                        </Text>
                        <Group gap="xs">
                            <EpisodesIcon />
                            <Text c="gray.7" fz="sm">
                                {podcast.episodes.length} Episodes
                            </Text>
                        </Group>
                        <Text c="gray.6" fz="xs" style={{ wordBreak: 'break-word' }}>
                            {removePTags(podcast.summary)}
                        </Text>
                    </Flex>
                </Flex>
            </Paper>
            {podcast.youtube_connected && podcast.youtube_meta.channelInfo && (
                <YoutubeChannelInfoModule channel={podcast.youtube_meta.channelInfo} />
            )}
        </Flex>
    )
}
