import {Button, Flex, Text, useDisclosure} from "@chakra-ui/react";
import {SplashLogo} from "../assets/SplashIcon";
import React, {useEffect} from "react";
import LibraryUploader from "./podcast/library-uploader";
import {useUser} from "../context/UserContext";
import {UserCategory} from "../models/Users";

export default function GetStartedModal() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const user: any = useUser()

    useEffect(() => {
        if (!user.userDataReq.loading && user.userDataReq.data) {
        } else if (!user.userDataReq.loading && user.userDataReq.data == null) {
            //handle the edge case when someone signs up with google from login
            window.location.href = "/signup/PUBLISHER/false/true"
        }

    }, [user.userDataReq.loading])

    return (
        <Flex w="full" flexDirection="column">
            <Flex w="full" p="56px 48px" flexDirection="column" justifyContent="center" alignItems="center" gap="56px" borderRadius="12px" bg="#FFF" boxShadow="0px 8px 24px 0px rgba(0, 0, 0, 0.06)">
                <SplashLogo/>
                <Text alignSelf="stretch" color="#2D3748" textAlign="center" fontSize="36px" fontWeight="500" lineHeight="40px">
                    Open your show to a global audience<br/>with <Text as="span" color="teal.500" fontWeight="semibold">BrainCap</Text>
                </Text>
                <Button size={'lg'} onClick={() => {onOpen()}} p="10px 24px" justifyContent="center" alignItems="center" gap="8px" flexShrink={0} alignSelf="stretch" borderRadius="6px" bg="teal.500">
                    <Text color="white" fontSize="18px" fontWeight="600" lineHeight="28px">Get Started</Text>
                </Button>
            </Flex>
            <LibraryUploader isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        </Flex>
    )
}
