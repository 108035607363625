import {useUser} from "../context/UserContext";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CartService} from "../services/cart-services";
import {PodcastCartItem} from "../models/Cart";

export const useCart = () => {
    const user: any = useUser();
    return useQuery({
        queryKey: ['cart', user.userId],
        queryFn: () => CartService.getCart(user.userId),
        enabled: !!user.userId,
        staleTime: 5 * 60 * 1000, // 5 minutes
        refetchOnWindowFocus: false,
    });
};

export const useAddToCart = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: { cart: PodcastCartItem[], publisherId: any }) => CartService.addToCart(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cart'] });
        },
    });
};

export const useRemoveFromCart = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: { episodeId: string, publisherId: string }) => CartService.removeFromCart(data),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['cart']});
        },
    });
};
