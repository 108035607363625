import { useQuery, useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { Podcast } from '../models/Podcast';
import { PodcastEpisode } from '../models/PodcastEpisode';
import { PodcastService } from '../services/podcast-services';
import {User} from "../models/Users";
import {LocalizationSession, TranscriptionSession} from "../models/Localization";

export const usePodcasts = (user: User): UseQueryResult<Podcast[]> => {
    return useQuery({
        queryKey: ['podcasts', user.id],
        queryFn: () => PodcastService.getPodcasts(user.id),
        enabled: !!user.id,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

export const useEpisodes = (podcastId: string): UseQueryResult<PodcastEpisode[]> => {
    return useQuery({
        queryKey: ['episodes', podcastId],
        queryFn: () => PodcastService.getEpisodes(podcastId),
        enabled: !!podcastId && podcastId !== '',
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

export const useEpisodesInReview = (user: User): UseQueryResult<TranscriptionSession[]> => {
    return useQuery({
        queryKey: ['episodesInReview', user.id],
        queryFn: () => PodcastService.getEpisodesInReview(user.id),
        enabled: !!user.id,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

export const useFetchRss = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: PodcastService.fetchRss,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['episodes'] });
        },
    });
};

export const useAddTagToEpisodes = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: PodcastService.addTagToEpisodes,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['episodes']});
        },
    });
};

export const useRemoveTagFromEpisode = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: PodcastService.removeTagFromEpisodes,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['episodes']});
        },
    });
}

