import { PodcastCartItem } from "../../models/Cart";
import { Text, Tooltip, Flex, ActionIcon, Table } from "@mantine/core";
import { convertSecondsToMinutes, countWords } from "../../utils/firebase";
import { useLocalization } from "../../context/LocalizationContext";
import { PodcastEpisode } from "../../models/PodcastEpisode";
import { secondsToHHMMSS } from "../../utils/text-utils";
import React, { useEffect, useState } from "react";
import { ENDPOINTS, useApi } from "../../api/braincap-api";
import { useUser } from "../../context/UserContext";
import { PodcastTranscriptionConfig, PodcastTranslationConfig } from "../../models/Localization";
import { IconTrash } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import {useCart, useRemoveFromCart} from "../../hooks/useCartQueries";
import {useFetchRss} from "../../hooks/usePodcastLibraryQueries";
import {useToast} from "@chakra-ui/react";

export const CartElement = ({ episode }: { episode: Partial<PodcastEpisode> }) => {
    return (
        <Flex direction="column" style={{ wordBreak: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip label={episode.title} position="top">
                <Text fw={500} lineClamp={2}>
                    {episode.title}
                </Text>
            </Tooltip>
            <Text c="dimmed" size="sm">
                {secondsToHHMMSS(episode.duration || 0)}
            </Text>
        </Flex>
    );
};

export const TranscriptionOnlyItems = ({ cart, setTotalPrice }: { cart: PodcastCartItem[], setTotalPrice: (price: number) => void }) => {
    const user: any = useUser();
    const removeCartItemReq = useApi(ENDPOINTS.CART_DELETE, user.token, false);
    const queryClient = useQueryClient();
    const { mutate: removeCartItem, isSuccess, isError  } = useRemoveFromCart();
    const toast = useToast()


    let finalPrice = 0;

    useEffect(() => {
        setTotalPrice(finalPrice);
    }, [finalPrice, setTotalPrice]);

    const handleRemoveCartItem = (cartItem: PodcastCartItem) => {
        if (cartItem.episode.id && user.userId) {
            removeCartItem({
                episodeId: cartItem.episode.id,
                publisherId: user.userId,
            });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast({
                title: 'Item removed from cart.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        }
        if (isError) {
            toast({
                title: 'Error removing item from cart.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }

    }, [isError, isSuccess]);

    useEffect(() => {
        if (!removeCartItemReq.loading && removeCartItemReq.data) {
            queryClient.invalidateQueries({ queryKey: ['cart'] });
        }
    }, [removeCartItemReq.loading, removeCartItemReq.data, queryClient]);

    const rows = cart.map((cartItem: PodcastCartItem) => {
        let item: PodcastTranscriptionConfig = cartItem as PodcastTranscriptionConfig;
        let duration = convertSecondsToMinutes(item.episode.duration);
        let totalPrice = duration * item.service.price;
        finalPrice += totalPrice;

        return (
            <Table.Tr key={item.episode.id}>
                <Table.Td>
                    <CartElement episode={item.episode} />
                </Table.Td>
                <Table.Td>{duration}</Table.Td>
                <Table.Td>{item.source_language.name}</Table.Td>
                <Table.Td style={{ textAlign: 'right' }}>${totalPrice.toFixed(2)}</Table.Td>
                <Table.Td>
                    <ActionIcon color="red" onClick={() => handleRemoveCartItem(item)}>
                        <IconTrash size={16} />
                    </ActionIcon>
                </Table.Td>
            </Table.Tr>
        );
    });

    return (
        <Table striped highlightOnHover>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>EPISODES</Table.Th>
                    <Table.Th>DURATION (MIN)</Table.Th>
                    <Table.Th>LANGUAGE</Table.Th>
                    <Table.Th style={{ textAlign: 'right' }}>PRICE</Table.Th>
                    <Table.Th></Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
            <Table.Tfoot>
                <Table.Tr>
                    <Table.Th colSpan={3}>Total</Table.Th>
                    <Table.Th style={{ textAlign: 'right' }}>${finalPrice.toFixed(2)}</Table.Th>
                    <Table.Th></Table.Th>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
    );
};

export const TranslationOnlyItems = ({ cart, setTotalPrice }: { cart: PodcastCartItem[], setTotalPrice: (price: number) => void }) => {
    const user: any = useUser();
    const removeCartItemReq = useApi(ENDPOINTS.CART_DELETE, user.token, false);
    const queryClient = useQueryClient();

    let finalPrice = 0;

    useEffect(() => {
        setTotalPrice(finalPrice);
    }, [finalPrice, setTotalPrice]);

    const handleRemoveCartItem = (cartItem: PodcastCartItem) => {
        if (cartItem.episode.id && user.userId) {
            removeCartItemReq.setPayloadData({
                episodeId: cartItem.episode.id,
                publisherId: user.userId,
            });
        }
    };

    useEffect(() => {
        if (!removeCartItemReq.loading && removeCartItemReq.data) {
            queryClient.invalidateQueries({ queryKey: ['cart'] });
        }
    }, [removeCartItemReq.loading, removeCartItemReq.data, queryClient]);

    const rows = cart.map((cartItem: PodcastCartItem) => {
        let item: PodcastTranslationConfig = cartItem as PodcastTranslationConfig;
        let nbLanguage = item.target_languages.length;
        let titleTranslationPrice = Math.round(countWords(item.episode.title) * 0.05);
        let descriptionTranslationPrice = Math.round(countWords(item.episode.description) * 0.05);
        let totalPrice = convertSecondsToMinutes(item.episode.duration) * nbLanguage * item.service.price + titleTranslationPrice + descriptionTranslationPrice;
        finalPrice += totalPrice;

        return (
            <Table.Tr key={item.episode.id}>
                <Table.Td>
                    <CartElement episode={item.episode} />
                </Table.Td>
                <Table.Td>{countWords(item.episode.title)}</Table.Td>
                <Table.Td>{countWords(item.episode.description)}</Table.Td>
                <Table.Td>{convertSecondsToMinutes(item.episode.duration)}</Table.Td>
                <Table.Td>{nbLanguage}</Table.Td>
                <Table.Td style={{ textAlign: 'right' }}>${totalPrice.toFixed(2)}</Table.Td>
                <Table.Td>
                    <ActionIcon color="red" onClick={() => handleRemoveCartItem(item)}>
                        <IconTrash size={16} />
                    </ActionIcon>
                </Table.Td>
            </Table.Tr>
        );
    });

    return (
        <Table striped highlightOnHover>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>EPISODES</Table.Th>
                    <Table.Th>TITLE (WORDS)</Table.Th>
                    <Table.Th>DESCRIPTION (WORDS)</Table.Th>
                    <Table.Th>DURATION (MIN)</Table.Th>
                    <Table.Th>LANGUAGES</Table.Th>
                    <Table.Th style={{ textAlign: 'right' }}>PRICE</Table.Th>
                    <Table.Th></Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
            <Table.Tfoot>
                <Table.Tr>
                    <Table.Th colSpan={5}>Total</Table.Th>
                    <Table.Th style={{ textAlign: 'right' }}>${finalPrice.toFixed(2)}</Table.Th>
                    <Table.Th></Table.Th>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
    );
};
