import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {createEditor, CustomText, Editor, Node, Point, Range, Text as SlateText, Transforms} from "slate";
import {FaBold, FaItalic, FaUnderline} from "react-icons/fa6";
import {withHistory} from "slate-history";
import {AiOutlineCloudDownload, AiOutlineFileSync} from "react-icons/ai";
import {Editable, ReactEditor, Slate, useFocused, useSlate, withReact} from "slate-react";
import {
    Box,
    Button,
    Center,
    Flex,
    Icon,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Skeleton,
    SkeletonText,
    Slide,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import {RiPriceTag3Line, RiQuestionMark} from "react-icons/ri";
import {useUser} from "../../context/UserContext";
import {useAuth} from "../../context/AuthContext";
import {apiStates, useApi} from "../../api/braincap-api";
import {handleSplitParagraph} from "../editors/slate-helpers";
import {ToolbarButton, ToolbarMenu, ToolbarPortal} from "../editors/toolbar-helper";
import {useParams} from "react-router-dom";
import {PodcastTranscriptionTaskMeta, TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {millisecondsToHHMMSS, secondsToHHMMSS} from "../../utils/firebase";
import {ActionFilter} from "../editors/transcription-helpers";
import {SlateParagraph, TranscriptMode} from "../../models/Transcript";
import {Document, Packer, Paragraph, TextRun} from "docx";
import {saveAs} from "file-saver";
import ReactPlayer from "react-player";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {useLibraryNavigation} from "../../context/LibraryNavigationContext";

export const TranscriptCompleteEditor = ({mode}: {mode: TranscriptMode}) => {
    const user: any = useUser()
    const auth: any = useAuth()
    const libraryNavigation: any = useLibraryNavigation()
    const { sessionId, version }: any = useParams();


    const editor = useMemo(() => withReact(withHistory(createEditor() as ReactEditor)), []);
    const [transcriptLoading, setTranscriptLoading] = useState<boolean>(true);
    const [task, setTask] = useState<TranscriptionSession>(libraryNavigation.studioSession);
    const [value, setValue] = useState<Node[]>([]);
    const [lastHighlightedWordIndex, setLastHighlightedWordIndex] = useState<number | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef: any = React.useRef()
    const [actions, setActions] = useState<any[]>([]);
    const [filteredActions, setFilteredActions] = useState<any[]>([]);
    const [openActionBox, setOpenActionBox] = useState<boolean>(false);
    const transcriptReq = useApi(`/transcription/transcript/get/${sessionId}`, user.token, false);
    const transcriptAsrReq = useApi(`/transcription/transcript/asr/get/${sessionId}`, user.token, false);
    const transcriptProofreadReq = useApi(`/transcription/transcript/proofread/get/${sessionId}`, user.token, false);
    const transcriptSaveReq = useApi(`/transcription/transcript/save/${sessionId}`, user.token, false);
    const [speakersList, setSpeakersList] = useState<any[]>([]);
    const [playSegment, setPlaySegment] = useState<boolean>(false);
    const [playSegmentStart, setPlaySegmentStart] = useState<number>(0);
    const toast = useToast();
    const transcriptRef: any = useRef(null);
    const [episode, setEpisode] = useState<PodcastEpisode>()

    useEffect(() => {
        if (mode === TranscriptMode.ASR) {
            transcriptAsrReq.execute()
        } else if (mode === TranscriptMode.PROOFREAD) {
            transcriptProofreadReq.execute()
        } else {
            transcriptReq.execute()
        }
    }, [])

    //add a timer to save the transcript every 2 minutes writing to the DB

    useEffect(() => {
        if (!transcriptReq.loading && !!transcriptReq.data) {
            setTask(transcriptReq.data.session)
            setValue(transcriptReq.data.transcript)
            setEpisode(transcriptReq.data.episode)
            setTranscriptLoading(false)
        }

        if (!transcriptReq.loading && transcriptReq.error) {
            toast({
                title: 'Error',
                description: 'Could not fetch transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
        }
    }, [transcriptReq.loading, transcriptReq.data])

    useEffect(() => {
        if (!transcriptAsrReq.loading && !!transcriptAsrReq.data) {
            setValue(transcriptAsrReq.data.transcript)
            setEpisode(transcriptAsrReq.data.episode)
            setTranscriptLoading(false)
        }

        if (!transcriptAsrReq.loading && transcriptAsrReq.error) {
            toast({
                title: 'Error',
                description: 'Could not fetch transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
        }
    }, [transcriptAsrReq.loading, transcriptAsrReq.data])

    useEffect(() => {
        if (!transcriptProofreadReq.loading && !!transcriptProofreadReq.data) {
            setTask(transcriptProofreadReq.data.session)
            setEpisode(transcriptProofreadReq.data.episode)
            setValue(transcriptProofreadReq.data.transcript)
            setEpisode(transcriptProofreadReq.data.episode)
            setTranscriptLoading(false)
        }

        if (!transcriptProofreadReq.loading && transcriptProofreadReq.error) {
            toast({
                title: 'Error',
                description: 'Could not fetch transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
        }
    }, [transcriptProofreadReq.loading, transcriptProofreadReq.data])

    useEffect(() => {
        setFilteredActions(actions)
    }, [actions])

    const handleAudioTimeUpdate = useCallback((currentTime: any) => {
        // currentTime is expected to be in seconds, convert it to milliseconds
        const currentTimeMs = currentTime * 1000;
        const nodes = value; // Assuming 'value' holds your nodes with time-stamped text

        // Find the node that corresponds to the current time
        const targetNode: any = nodes.find((node: any) => {
            const { start, end } = node.children[0]; // Assuming the first child has the timestamp
            return start <= currentTimeMs && currentTimeMs <= end;
        });

        if (transcriptRef.current && targetNode) {
            // Find the DOM element that corresponds to the node
            // For this, you might have to assign an ID or some other identifier to each node's DOM element when rendering the transcript
            const elementId = `transcript-${targetNode.children[0].start}`; // This assumes you give each text node a unique ID based on its start time
            const element = document.getElementById(elementId);

            if (element) {
                // Scroll to the DOM element
                transcriptRef.current.scrollTop = element.offsetTop - transcriptRef.current.offsetTop;
            }
        }
    }, [value]);

    const getSlateContent = () => {
        return value;
    };

    const WordSpans = ({...props}) => {
        return (
            <>
                {props.element.children[0].words.map((word:any, index: any) => {
                    const wordAttributes = {
                        'data-word-start': word.start,
                        'data-word-end': word.end,
                        'data-word-id': word.id,
                    };
                    return (
                        <Box
                            as="span"
                            id={`${word.start}-${word.end}`}
                            key={index}
                            mr={index !== props.element.children[0].words.length - 1 ? 2 : 0} // Add some margin to separate words
                            display="inline-block"
                            {...wordAttributes}
                        >
                            {word.text}
                        </Box>
                    )
                })
                }
            </>
        )
    }

    const renderElement = useCallback(({...props}) => {
        let list = props.element.speakersList
        switch (props.element.type) {
            case 'timedText':
                return (
                    <Flex direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} w={'full'} {...props.attributes}>
                        <Menu>
                            <MenuButton userSelect={'none'}>
                                <Text contentEditable={false} color="teal.600" fontSize="12px" fontWeight="600" lineHeight="16px">
                                    {props.element.speaker}
                                </Text>
                            </MenuButton>
                            <MenuList>
                                {list.map((speaker: any) => {
                                    return (
                                        <MenuItem contentEditable={false} onClick={handleSetSpeaker.bind(this, props, speaker.name, true)}>
                                            {speaker.name}
                                        </MenuItem>
                                    )
                                })}
                            </MenuList>
                        </Menu>
                        <p>
                            {props.children}
                        </p>
                        <br/>
                    </Flex>
                )
            default:
                return <DefaultElement {...props} />;

        }
    }, []);

    const renderLeaf = useCallback(({ attributes, children, leaf }: { attributes: any, children: any, leaf: any }) => {
        const extendedAttributes = {
            ...attributes,
            'data-start': leaf.start,
            'data-end': leaf.end
        };

        if (leaf.isAdvertisement) {
            children = <span style={{ backgroundColor: '#FFFFF0', color: '#B7791F' }}>{children}</span>;
        } else if (leaf.isInaudibleContent) {
            children = <span style={{ backgroundColor: '#FFF5F5', color: '#C53030'}}>{children}</span>;
        } else {
            children = <span style={{ backgroundColor: 'transparent' }}>{children}</span>;
        }

        if (leaf.highlight) {
            children = <span style={{ backgroundColor: '#FDE047' }}>{children}</span>;
        }
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underlined) {
            children = <u>{children}</u>
        }

        return <span {...extendedAttributes}>{children}</span>;
    }, []);

    const handleSetSpeaker = (props: any, name: string) => {
        const pathToCurrentNode = ReactEditor.findPath(editor, props.element);
        const oldSpeakerName = props.element.speaker;
        const isUpdateAllSpeakerInstances = window.confirm(`Would you like to replace all occurrences of ${oldSpeakerName} with ${name}?`);

        if (isUpdateAllSpeakerInstances) {
            const rangeForTheWholeEditor = Editor.range(editor, []);
            Transforms.setNodes(
                editor,
                { type: 'timedText', speaker: name } as Partial<Node>,
                {
                    at: rangeForTheWholeEditor,
                    match: (node: any) => node.type === 'timedText' && node.speaker.toLowerCase() === oldSpeakerName.toLowerCase(),
                }
            );
        } else {
            Transforms.setNodes(editor, {type: 'timedText', speaker: name} as Partial<Node>, { at: pathToCurrentNode })
        }
    }

    const DefaultElement = ({...props}) => {
        return (
            <>
                <p {...props.attributes}>{props.children}</p>
                <br/>
            </>
        );
    };

    const handleChange = (data: any) => {
        setValue(data);
        //saveLocalTranscript(data);
        if (actions.length > 0) {
            setOpenActionBox(true)
        } else {
            setOpenActionBox(false)
        }
    };

    const handleOnKeyDown = (event: any) => {
        if (event.key === '&') {
            // Prevent the ampersand character from being inserted.
            event.preventDefault()
            // Execute the `insertText` method when the event occurs.
            editor.insertText('and')
        }

        if (event.key === 'Enter') {
            // intercept Enter, and handle timecodes when splitting a paragraph
            event.preventDefault();
            // console.info('For now disabling enter key to split a paragraph, while figuring out the aligment issue');
            // handleSetPauseWhileTyping();
            // TODO: Edge case, hit enters after having typed some other words?
            handleSplitParagraph(editor);
        }
    }

    const getHighlightedTimestamps = (editor: any) => {
        const { selection } = editor;

        if (!selection || Range.isCollapsed(selection)) return;

        const isReversed = Point.isAfter(selection.anchor, selection.focus);

        const textNodes = Array.from(
            Editor.nodes(editor, {
                at: selection,
                match: n => SlateText.isText(n),
            })
        );

        if (!textNodes.length) return;
        console.log(textNodes)

        const [firstTextNode, firstPath] = textNodes[0];
        const [lastTextNode, lastPath] = textNodes[textNodes.length - 1];


        const selectedText = Editor.string(editor, selection);

        return { startTime: 'NaN', endTime: 'Nan', text: selectedText };
    };

    const findWordForTextNode = (textNode: any, path: any, editor: any, offset: number) => {
        const [parentNode]: any = Editor.parent(editor, path);

        if (!parentNode.children || !parentNode.children[0].words) return null;

        const wordsArray = parentNode.children[0].words;

        // Split the textNode text into individual words
        const textNodeWords = textNode.text.split(/\s+/);

        // Determine which word the offset falls within
        let currentLength = 0;
        let targetWord: any = null;
        for (let word of textNodeWords) {
            currentLength += word.length + 1; // +1 for the space
            if (currentLength >= offset) {
                targetWord = word;
                break;
            }
        }

        if (!targetWord) return null;

        // Find the corresponding word object for the target word
        const wordObj = wordsArray.find((word: any) => word.text === targetWord);

        return wordObj;
    }


    const HoveringToolbar = () => {
        const ref = useRef<HTMLDivElement | null>()
        const editor = useSlate()
        const inFocus = useFocused()
        const [openEditBox, setOpenEditBox] = useState<boolean>(false)

        useEffect(() => {
            try {
                const el = ref.current
                const { selection } = editor
                if (!el) {
                    return
                }

                if (
                    !selection ||
                    !inFocus ||
                    Range.isCollapsed(selection) ||
                    Editor.string(editor, selection) === ''
                ) {
                    el.removeAttribute('style')
                    return
                }

                const domSelection: any = window.getSelection()
                const domRange = domSelection.getRangeAt(0)
                const rect = domRange.getBoundingClientRect();
                el.style.opacity = '1';
                el.style.top = `${rect.bottom + window.scrollY}px`;
                el.style.left = `${rect.left + window.scrollX + (rect.width / 2) - (el.offsetWidth / 2)}px`// <-- Call the function here
            } catch (e) {
                console.log(e)
            }

        } )

        return (
            <ToolbarPortal  ref={ref}>
                <ToolbarMenu
                    ref={ref}
                    onMouseDown={(e: any) => {e.preventDefault()}}
                >
                    <AdvertisementButton
                        icon={<RiPriceTag3Line style={{color: '#B7791F',}}/>}
                        name={'Advertisement'}
                        activeName={'Remove Advertisement'}
                    />
                    <InaudibleContentButton
                        icon={<RiQuestionMark style={{color: '#C53030'}}/>}
                        name={'Inaudible Content'}
                        activeName={'Remove Inaudible Content'}
                    />
                    <FormatButton format="bold" icon={<FaBold />} />
                    <FormatButton format="italic" icon={<FaItalic />} />
                    <FormatButton format="underlined" icon={<FaUnderline />} />
                </ToolbarMenu>
            </ToolbarPortal>
        )
    }

    const toggleFormatMark = (editor: any, format: any, start: any, end: any) => {
        const isActive = isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    const EditContentButton = ({ format, icon, name, activeName, openEditBox }: { format: any, icon: any, name: string, activeName: string, openEditBox: any }) => {
        const editor = useSlate()
        const isActive = isMarkActive(editor, format)
        let data = getHighlightedTimestamps(editor)
        let action = {
            ...data,
            type: 'inaudibleContent'
        }
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, format)}
                onClick={() => {openEditBox(true)}}
            >
                {icon}
                <Text color="gray.600" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                    {isActive? activeName : name}
                </Text>
            </ToolbarButton>
        )
    }

    const FormatButton = ({ format, icon }: { format: any, icon: any}) => {
        const editor = useSlate()
        let data = getHighlightedTimestamps(editor)
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, format)}
                onClick={() => toggleFormatMark(editor, format, data?.startTime, data?.endTime)}
            >
                <Icon>{icon}</Icon>
            </ToolbarButton>
        )
    }

    const AdvertisementButton = ({ icon, name, activeName }: { icon: any, name: string, activeName: string }) => {
        const editor = useSlate()
        const isActive = isMarkActive(editor, 'isAdvertisement')
        let data = getHighlightedTimestamps(editor)
        let action = {
            ...data,
            type: 'advertisement'
        }
        return (
            <ToolbarButton
                reversed
                active={isActive}
                onClick={() => {toggleMark(editor, data?.startTime, data?.endTime)}}
                bg={isActive? "yellow.50" : "white"}
            >
                {icon}
                <Text color={isActive? "yellow.600" : "gray.600"} fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                    {isActive? activeName : name}
                </Text>
            </ToolbarButton>
        )
    }

    const InaudibleContentButton = ({ icon, name, activeName }: { icon: any, name: string, activeName: string }) => {
        const editor = useSlate()
        const isActive = isMarkActive(editor, 'isInaudibleContent')
        let data = getHighlightedTimestamps(editor)
        let action = {
            ...data,
            type: 'inaudibleContent'
        }
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, 'isInaudibleContent')}
                onClick={() => {InaudibleMark(editor, data?.startTime, data?.endTime)}}>
                {icon}
                <Text color="gray.600" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                    {isActive? activeName : name}
                </Text>
            </ToolbarButton>
        )
    }

    const toggleMark = (editor: any, start: any, end: any) => {
        const isActive = isMarkActive(editor, 'isAdvertisement')
        console.log(`adstimestamps: ${start} - ${end}`)

        if (isActive) {
            Editor.removeMark(editor, 'isAdvertisement')
        } else {
            Editor.addMark(editor, 'isAdvertisement', true)
            Editor.addMark(editor, 'start', start)
            Editor.addMark(editor, 'end', end)
        }
    }

    const InaudibleMark = (editor: any, start: any, end: any) => {
        const isActive = isMarkActive(editor, 'isInaudibleContent')
        console.log(`inaudibletimestamps: ${start} - ${end}`)
        if (isActive) {
            Editor.removeMark(editor, 'isInaudibleContent')
        } else {
            Editor.addMark(editor, 'isInaudibleContent', true)
            Editor.addMark(editor, 'start', start)
            Editor.addMark(editor, 'end', end)
        }
    }

    const isMarkActive = (editor: any, format: any) => {
        const marks: any = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }


    const handleTimeUpdate = (e: any) => {
        const currentTime = e; // Convert to milliseconds

        // Find the word that matches the current playback time
        const val = findWordByTime(Math.round(currentTime), value)
        const wordToHighlight = val?.wordToHighlight;
        const node = val?.node;

        if (wordToHighlight) {
            if (lastHighlightedWordIndex !== null) {
                // Highlight all words between the last highlighted word and the current word
                for (let i = lastHighlightedWordIndex + 1; i <= wordToHighlight.id; i++) {
                    const word = findWordById(i, value);
                    if (word && !word.highlight) {
                        highlightWord(word, node);
                    }
                }
            } else {
                highlightWord(wordToHighlight, node);
            }
            setLastHighlightedWordIndex(wordToHighlight.id);
        }
    };

    /**
     * This function uses binary search to find a word in the transcript based on a given timestamp.
     *
     * @param {number} time - The current timestamp (in milliseconds) from the audio player.
     * @param {Array} wordsArray - The array of words from the transcript.
     *
     * @returns {Object|null} - Returns the word object that matches the given timestamp or null if no word is found.
     */
    const findWordByTimeBinarySearch = (time: any, wordsArray: any) => {
        const BUFFER = 5;  // 5 milliseconds buffer

        let low = 0;
        let high = wordsArray.length - 1;

        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            const word = wordsArray[mid];

            if (time >= word.start - BUFFER && time <= word.end + BUFFER) {
                return word;
            } else if (time < word.start - BUFFER) {
                high = mid - 1;
            } else {
                low = mid + 1;
            }
        }

        // If we're here, it means the time is in between two words.
        // Return the earlier word if `high` is within bounds.
        if (high >= 0) {
            return wordsArray[high];
        }

        return null;
    };

    const findWordById = (id: number, value: any) => {
        for (let node of value) {
            if (node.children && node.children[0].words) {
                const word = node.children[0].words.find((w: any) => w.id === id);
                if (word) {
                    return word;
                }
            }
        }
        return null;
    };

    const findWordByTime = (time: any, value: any) => {
        for (let node of value) {
            if (node.children && node.children[0].words) {
                const wordObj = findWordByTimeBinarySearch(time, node.children[0].words);
                if (wordObj) {
                    return {wordToHighlight: wordObj, node: node}
                }
            }
        }
        return null;
    };

    const findWordByIteratingThroughSpans = (time: any) => {
        //get all spans with data-start and data-end attributes
        const attributeName = 'data-word-start'; // Replace with your specific attribute name
        const elements = Array.from(document.querySelectorAll(`[${attributeName}]`));
        console.log('elements:', elements.length)
        for (let span of elements) {
            const start = Number(span.getAttribute('data-word-start'));
            const end = Number(span.getAttribute('data-word-end'));
            const id = Number(span.getAttribute('data-word-id'));
            if (start && end && time >= start && time <= end) {
                console.log('Highlighting id:', span.getAttribute('data-word-id'))
                span.setAttribute('style', 'background-color: yellow;')
            }
        }
    }
    const findWordByBinarySearch = (time: any) => {
        const BUFFER = 5;  // 5 milliseconds buffer
        const attributeName = 'data-word-start'; // Replace with your specific attribute name
        const elements = Array.from(document.querySelectorAll(`[${attributeName}]`));
        let currentHighlightedWordId = null;

        let left = 0;
        let right = elements.length - 1;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const span = elements[mid];
            const start = Number(span.getAttribute('data-word-start'));
            const end = Number(span.getAttribute('data-word-end'));
            const id = Number(span.getAttribute('data-word-id'));

            if (time >= start - BUFFER && time <= end + BUFFER) {
                // Highlight the matching span
                span.setAttribute('style', 'background-color: teal;');
                for (let i = mid - 1; i >= 0; i--) {
                    elements[i].setAttribute('style', 'background-color: transparent;');
                    //remove highlight from previous spans
                }
                return;
            } else if (time < start - BUFFER) {
                right = mid - 1;
            } else {
                left = mid + 1;
            }
        }

        if (right >= 0) {
            return elements[right];
        }
        return null;
    };
    const highlightWord = (wordObj: any, node: any) => {
        console.log("Trying to highlight node:", node);
        const pathToCurrentNode = ReactEditor.findPath(editor, node);
        console.log("Path to current node:", pathToCurrentNode);

        Transforms.setNodes(
            editor,
            { highlight: true } as Partial<CustomText>,
            {
                at: pathToCurrentNode,
                match: n => SlateText.isText(n) && n.text === wordObj.text,
            }
        );
    };
    const ActionComponent = ({type, text, start, end}: {type: any, text: any, start: any, end: any}) => {
        return (
            <Flex direction={'column'} w={'full'} p={'12px'} gap={'12px'} justifyContent={'flex-start'}>
                <Flex direction={'row'} alignItems={'center'} gap={'6px'}>
                    {type === 'advertisement' ?
                        <RiPriceTag3Line width={'28px'} height={'28px'} style={{backgroundColor: '#FFFFF0', color: '#B7791F', borderRadius: '9999px'}}/>

                        :
                        <RiQuestionMark width={'28px'} height={'28px'} style={{backgroundColor: '#FFF5F5', color: '#C53030', borderRadius: '9999px'}}/>

                    }
                    <Text color="gray.400" fontSize="10px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                        {millisecondsToHHMMSS(start)} - {millisecondsToHHMMSS(end)}
                    </Text>
                </Flex>
                <Tooltip label={text} placement={'left-start'} hasArrow>
                    <Text color="gray.800" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px" onClick={() => {
                        setPlaySegmentStart(start)
                        setPlaySegment(true)
                    }}>
                        {text}
                    </Text>
                </Tooltip>
            </Flex>
        )
    }

    const updateActions = (newAction: any) => {
        let current = actions
        current.push(newAction)
        setActions(current)
    }

    const filterActions = (type: ActionFilter) => {
        let current = actions
        switch (type) {
            case ActionFilter.ALL: {
                current = current.filter((action: any) => action.type === 'advertisement' || action.type === 'inaudible')
                break;
            }
            case ActionFilter.ADVERTISEMENT: {
                current = current.filter((action: any) => action.type === 'advertisement')
                break;
            }
            case ActionFilter.INAUDIBLE_CONTENT: {
                current = current.filter((action: any) => action.type === 'inaudible')
                break;
            }
        }
        setFilteredActions(current)

    }

    let image_url = "https://megaphone.imgix.net/podcasts/042e6144-725e-11ec-a75d-c38f702aecad/image/Huberman-Lab-Podcast-Thumbnail-3000x3000.png?ixlib=rails-4.3.1&max-w=3000&max-h=3000&fit=crop&auto=format,compress"

    const convertToSlateParagraph = (data: any[]): SlateParagraph[] => {
        return data.map((item, index) => {
            // No conditional spacing added, just combine texts
            const combinedText = item.children.map((child: any) => child.text).join('');
            return {
                id: `${index}`,
                text: combinedText,
                start: item.start,
                end: item.end,
                speaker: item.speaker
            };
        });
    }

    const createReadableTextFileWithSpeaker = async (): Promise<Paragraph[]> => {
        let data = convertToSlateParagraph(value);
        console.log(`Conversion complete`);

        let speaker = '';
        let paragraphs: Paragraph[] = [];
        paragraphs.push(new Paragraph({
            children: [new TextRun({
                text: (task as PodcastTranscriptionTaskMeta)?.episode.title as string,
                bold: true,
                size: 36,
            })],
            spacing: { after: 200 }, // Adds space after the title
        }));

        data.forEach(item => {
            if (speaker !== item.speaker) {
                speaker = item.speaker;
                paragraphs.push(new Paragraph({
                    children: [
                        new TextRun({
                            text: `${speaker}: `,
                            bold: true,
                        }),
                        new TextRun({
                            text: item.text,
                        })
                    ],
                    spacing: { after: 120 }, // Adjust space after each speaker change
                }));
            } else {
                paragraphs.push(new Paragraph({
                    children: [new TextRun({
                        text: item.text,
                    })],
                    spacing: { after: 120 }, // Consistent spacing for readability
                }));
            }
        });

        return paragraphs;
    };

    const generateDocument = () => {
        let transcriptText = createReadableTextFileWithSpeaker()
        transcriptText.then((pagraphs) => {
            const doc: any = new Document({
                sections: [
                    {
                        properties: {},
                        children: pagraphs,
                    },
                ],
            });

            Packer.toBlob(doc).then((blob) => {
                saveAs(blob, `${(task as PodcastTranscriptionTaskMeta)?.episode.title}-test.docx`);
            });
        })
    }

    // @ts-ignore
    // @ts-ignore
    return (
        <Flex w={'full'} direction={'column'} gap={'36px'} mt={'8px'}>
            <Flex ref={transcriptRef} w={'full'} p={'20px'} h={'100vh'} style={{ overflow: 'scroll' }}>
                {transcriptLoading?
                    <SkeletonText w={'full'} h={'full'} mt='4' noOfLines={60} spacing='4' skeletonHeight='2' />
                    :
                    <Flex direction={'column'} gap={'10px'} align={'center'} justify={'center'} w={'100%'} h={'100%'}>
                        <Slate
                            editor={editor}
                            onChange={handleChange}
                            value={value}>
                            <HoveringToolbar />
                            <Editable
                                contentEditable={false}
                                renderElement={renderElement}
                                renderLeaf={renderLeaf}
                                onKeyDown={handleOnKeyDown}
                                autoFocus
                                style={{ height: '100%', overflowY: 'scroll' }}
                            />
                        </Slate>
                    </Flex>
                }
            </Flex>
        </Flex>
    );
}
