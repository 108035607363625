import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useUser} from "./UserContext";
import {ENDPOINTS, useApi} from "../api/braincap-api";
import {Subscription, SupportedLanguages} from "../models/AppConfig";
import {BraincapService, LocalizationProcessingType, ServiceType, TranscriptionState} from "../models/Localization";
import {Publisher} from "../models/Publisher";
import {MediaContentCartItem, PodcastCartItem} from "../models/Cart";
import {PodcastEpisode} from "../models/PodcastEpisode";
import {Podcast} from "../models/Podcast";
import {usePodcastLibrary} from "./PodcastLibraryContext";
import {useCart} from "../hooks/useCartQueries";

export const LocalizationContext = createContext(null);

export interface ConfigurationValidation {
    service: boolean,
    source: boolean,
    publisher_editing?: boolean,
    host: boolean,
    guest: boolean,
    target?: boolean,
}
export default function LocalizationProvider (props: any) {
    const user: any = useUser()
    const [supportedLanguages, setSupportedLanguages] = useState<SupportedLanguages>()
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
    const [services, setServices] = useState<Map<string, BraincapService>>(new Map<string, BraincapService>())
    const [appConfigLoading, setAppConfigLoading] = useState<boolean>(false)
    const [appConfigDownloaded, setAppConfigDownloaded] = useState<boolean>(false)
    const [episodesConfiguration, setEpisodesConfiguration] = useState<Map<string, Partial<PodcastCartItem>>>(new Map<string, Partial<PodcastCartItem>>())
    const [mediaContentConfiguration, setMediaContentConfiguration] = useState<Map<string, Partial<MediaContentCartItem>>>(new Map<string, Partial<MediaContentCartItem>>())
    const [configurationValidation, setConfigurationValidation] = useState<Map<string, ConfigurationValidation>>(new Map<string, ConfigurationValidation>())
    const [isConfigurationValid, setIsConfigurationValid] = useState<boolean>(false)
    const {currentPodcast: podcast} = usePodcastLibrary()

    const [cartMap, setCartMap] = useState<any>({})
    const { data: cartData = [] , isLoading: cartLoading, isError: cartError} = useCart()


    const cart = useMemo(() => cartData || [], [cartData]);

    useEffect(() => {
        if (cart.length > 0) {
            let mapping: Map<string, PodcastCartItem> = new Map<string, PodcastCartItem>()

            cart.forEach((cartItem: PodcastCartItem) => {
                mapping.set(cartItem.episode.id as string, cartItem)
            })
            setCartMap(mapping)
        }
    }, [cart]);



    const configReq = useApi(ENDPOINTS.GET_APP_CONFIG, user.token, false)
    const addItemsToCartReq = useApi(`${ENDPOINTS.CART_ADD}/${user.userId}`, user.token, false)

    useEffect(() => {
        if (!!user.userId) {
            setAppConfigLoading(true)
            configReq.execute()
        }
    }, [user.userId])

    useEffect(() => {
        if (!configReq.loading && configReq.data) {
            setSupportedLanguages(configReq.data.languages)
            let subscription: any = (user.user as Publisher).subscription.type
            let current = services
            configReq.data.services[subscription].forEach((service: BraincapService) => {
                current.set(service.type, service)
            })
            setServices(current)
            setSubscriptions(configReq.data.subscriptions)
            setAppConfigLoading(false)
            setAppConfigDownloaded(true)
        }
    }, [configReq.loading])

    const validateConfiguration = (): boolean => {
        let isInvalid = false
        let validations: any = {...configurationValidation}
        Object.entries(episodesConfiguration).forEach((config  ) => {
            const [key, value] = config;
            let validation = {...validations[key]}
            let speakers: any[] = value.speakers
            switch (value.service?.type) {
                case ServiceType.TRANSCRIPTION: {
                    if (!value?.source_language === null || value.speakers.length === 0) {
                        isInvalid = true
                    }
                    break
                }
                case ServiceType.TRANSLATION: {
                    console.log(`value: ${JSON.stringify(value)}`)
                    if (value?.episode.transcription.state === TranscriptionState.NOT_CREATED) {
                        if (value?.source_language === null || value?.target_languages.length === 0 || value?.speakers.length === 0 || value?.pre_translation_editing === null) {
                            isInvalid = true
                        }
                    } else {
                        if (value?.target_languages.length === 0 || value?.speakers.length === 0 || value?.pre_translation_editing === null) {
                            isInvalid = true
                        }
                    }
                    break
                }
                case ServiceType.DUBBING: {
                    if (value.episode.transcription.state === TranscriptionState.NOT_CREATED) {
                        if (value?.source_language === null || value?.target_languages.length === 0 || value?.speakers.length === 0 || value?.pre_translation_editing === null) {
                            isInvalid = true
                        }
                    } else {
                        if (value?.target_languages.length === 0 || value?.speakers.length === 0 || value?.pre_translation_editing === null) {
                            isInvalid = true
                        }
                    }
                }
            }
        })

        return isInvalid
    }
    const handleAddToCart = () => {
        if (!isConfigurationValid) {
            let cartItems: PodcastCartItem[] = []

            console.log(`cartItems: ${JSON.stringify(episodesConfiguration)}`)

            Object.values(episodesConfiguration).forEach((value: Partial<PodcastCartItem>) => {
                cartItems.push(value as PodcastCartItem)
            })


            addItemsToCartReq.setPayloadData({
                cart: cartItems,
            } as any)

        }
    }
    const configureSingleEpisode = (e: boolean, podcastEpisode: PodcastEpisode) => {
        if (e) {
            setEpisodesConfiguration({
                ...episodesConfiguration,
                [podcastEpisode.id]: {
                    episode: {
                        id: (podcastEpisode as PodcastEpisode).id,
                        title: (podcastEpisode as PodcastEpisode).title,
                        duration: (podcastEpisode as PodcastEpisode).duration,
                        description: (podcastEpisode as PodcastEpisode).description,
                        URL: (podcastEpisode as PodcastEpisode).URL,
                        image_url: (podcastEpisode as PodcastEpisode).image_url,
                        media_type: (podcastEpisode as PodcastEpisode).media_type,
                        transcription: (podcastEpisode as PodcastEpisode).transcription,
                        translation: (podcastEpisode as PodcastEpisode).translation,
                        dubbing: (podcastEpisode as PodcastEpisode).dubbing,
                    },
                    podcast: {
                        id: podcast?.id,
                        title: podcast?.title,
                        rss_feed: podcast?.rss_feed,
                        summary: podcast?.summary,
                        publisher: podcast?.publisher,
                        categories: podcast?.categories,
                    },
                    customer: {
                        id: user.user.id,
                        name: user.user.name,
                        email: user.user.email,
                        phone: user.user.phone,
                        category: user.user.category,
                    },
                    processing_type: LocalizationProcessingType.POST_PUBLISHING,
                }
            })

            //Only set validation general for all services
            setConfigurationValidation({
                ...configurationValidation,
                [podcastEpisode.id]: {
                    service: false,
                    host: false,
                    guest: false,
                    source: false,
                }
            })
        }

        if (!e) {
            let config: any = { ...episodesConfiguration };
            let validation: any = { ...configurationValidation };
            delete config[podcastEpisode.id];
            delete validation[podcastEpisode.id];
            setEpisodesConfiguration(config);
            setConfigurationValidation(validation);
        }
    }

    const resetConfiguration = () => {
        setEpisodesConfiguration(new Map<string, Partial<PodcastCartItem>>())
        setConfigurationValidation(new Map<string, ConfigurationValidation>())
    }
    const configureAllEpisodes = (e: any, episodes: PodcastEpisode[]) => {
        let config: any = { ...episodesConfiguration };
        let validation: any = { ...configurationValidation };
        if (e.target.checked) {
            // get the episodes for which the transcription.state === not_Created and is not in cart

            episodes.forEach((podcastEpisode: PodcastEpisode) => {
                if (!cartMap.has(podcastEpisode.id)) {
                    config = {
                        ...config,
                        [podcastEpisode.id]: {
                            episode: {
                                id: (podcastEpisode as PodcastEpisode).id,
                                title: (podcastEpisode as PodcastEpisode).title,
                                duration: (podcastEpisode as PodcastEpisode).duration,
                                description: (podcastEpisode as PodcastEpisode).description,
                                URL: (podcastEpisode as PodcastEpisode).URL,
                                image_url: (podcastEpisode as PodcastEpisode).image_url,
                                media_type: (podcastEpisode as PodcastEpisode).media_type,
                                transcription: (podcastEpisode as PodcastEpisode).transcription,
                                translation: (podcastEpisode as PodcastEpisode).translation,
                                dubbing: (podcastEpisode as PodcastEpisode).dubbing,
                            },
                            podcast: {
                                id: podcast?.id,
                                title: podcast?.title,
                                rss_feed: podcast?.rss_feed,
                                summary: podcast?.summary,
                                publisher: podcast?.publisher,
                                categories: podcast?.categories,
                            },
                            customer: {
                                id: user.user.id,
                                name: user.user.name,
                                email: user.user.email,
                                phone: user.user.phone,
                                category: user.user.category,
                            },
                            processing_type: LocalizationProcessingType.POST_PUBLISHING,
                        }
                    }
                    validation = {
                        ...validation,
                        [podcastEpisode.id]: {
                            service: false,
                            host: false,
                            guest: false,
                            source: false,
                        }
                    }
                }
            })
            setEpisodesConfiguration(config);
            setConfigurationValidation(validation);
        }

        if (!e.target.checked) {
            resetConfiguration()
        }
    }

    const getService = (type: any): any => {
        console.log(`Getting service for type: ${services.get(type)}`)
    }

    return (
        <LocalizationContext.Provider value = {{
            services: services,
            appConfigLoading: appConfigLoading,
            appConfigDownloaded: appConfigDownloaded,
            supportedLanguages: supportedLanguages,
            episodesConfiguration: episodesConfiguration,
            setEpisodesConfiguration: (data: Map<string, Partial<PodcastCartItem>>) => {
                setEpisodesConfiguration({...data})
            },
            mediaContentConfiguration: mediaContentConfiguration,
            setMediaContentConfiguration: (data: Map<string, Partial<MediaContentCartItem>>) => {
                setMediaContentConfiguration({...data})
            },
            configurationValidation: configurationValidation,
            setConfigurationValidation: setConfigurationValidation,
            subscriptions: subscriptions,
            handleAddToCart: handleAddToCart,
            isConfigurationValid: isConfigurationValid,
            validateConfiguration: validateConfiguration,
            addItemsToCartReq: addItemsToCartReq,
            configureSingleEpisode: configureSingleEpisode,
            configureAllEpisodes: configureAllEpisodes,
            resetConfiguration: resetConfiguration,
            getService: getService,
        }} {...props} />
    )
}

export const useLocalization = () => useContext(LocalizationContext)
