import {usePodcastLibrary} from "../../context/PodcastLibraryContext";
import {useLibraryNavigation} from "../../context/LibraryNavigationContext";
import React, {useEffect, useState} from "react";
import {TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {IconCircleCheck, IconCircleDashedCheck, IconProgressCheck} from "@tabler/icons-react";
import {Tabs} from "@mantine/core";
import TranscriptReader from "../../pages/transcript-reader";
import {TranscriptMode} from "../../models/Transcript";


enum TaskState {
    NOT_STARTED = 'Not Started',
    IN_PROGRESS = 'In Progress',
    COMPLETED = 'Completed',
}

export default function TranscriptionTaskSidebar() {
    const libraryNavigation: any = useLibraryNavigation()
    const [session, setSession] = useState<TranscriptionSession>(libraryNavigation.studioSession)
    const [taskStates, setTaskStates] = useState<any>({
        AI: TaskState.NOT_STARTED,
        TRANSCRIPTIONIST: TaskState.NOT_STARTED,
        EDITOR: TaskState.NOT_STARTED,
        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
        FINAL: TaskState.NOT_STARTED
    })

    useEffect(() => {
        if (session) {
            switch (session.state) {
                case TranscriptionState.SPEECH_TO_TEXT: {
                    setTaskStates({
                        AI: TaskState.IN_PROGRESS,
                        TRANSCRIPTIONIST: TaskState.NOT_STARTED,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PROOFREADING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.IN_PROGRESS,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PROOFREADING_COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }

                case TranscriptionState.READY_FOR_PROOFREADING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.IN_PROGRESS,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.EDITOR_REVIEWING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.EDITOR_REVIEW: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }

                case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.IN_PROGRESS,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PUBLISHER_REVIEWING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.IN_PROGRESS,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.COMPLETED,
                        FINAL: TaskState.IN_PROGRESS
                    })
                    break
                }
            }
        }
    }, []);

    const StateIcon = ({state}: {state: TaskState}) => {
        return (
            <>
                {state === TaskState.NOT_STARTED && <IconCircleDashedCheck stroke={2} style={{color: 'gray'}}/>}
                {state === TaskState.IN_PROGRESS && <IconProgressCheck stroke={2} style={{color: 'orange'}}/>}
                {state === TaskState.COMPLETED && <IconCircleCheck stroke={2} style={{color: 'teal'}}/>}
            </>
        )
    }

    return (
        <Tabs defaultValue="first" w={'100%'}>
            <Tabs.List grow justify="center" bg={'white'}>
                <Tabs.Tab value="first" rightSection={<StateIcon state={taskStates.AI}/>}>AI</Tabs.Tab>
                <Tabs.Tab value="second" rightSection={<StateIcon state={taskStates.TRANSCRIPTIONIST}/>}>Transcriptionist</Tabs.Tab>
                <Tabs.Tab value="third" rightSection={<StateIcon state={taskStates.EDITOR}/>}>Editor</Tabs.Tab>
                <Tabs.Tab value="fifth" rightSection={<StateIcon state={taskStates.FINAL}/>}>Final</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="first">
                {taskStates.AI === TaskState.COMPLETED && <TranscriptReader mode={TranscriptMode.ASR}/>}
            </Tabs.Panel>

            <Tabs.Panel value="second">
                {taskStates.TRANSCRIPTIONIST === TaskState.COMPLETED && <TranscriptReader mode={TranscriptMode.PROOFREAD}/>}
            </Tabs.Panel>

            <Tabs.Panel value="third">
                {taskStates.PUBLISHER_REVIEW === TaskState.COMPLETED && <TranscriptReader mode={TranscriptMode.REVIEWING}/>}
            </Tabs.Panel>
            <Tabs.Panel value="fifth">
                {taskStates.FINAL === TaskState.COMPLETED && <TranscriptReader mode={TranscriptMode.COMPLETED}/>}
            </Tabs.Panel>
        </Tabs>
    )
}
