import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {apiStates, ENDPOINTS, useApi} from "../../api/braincap-api";

interface LoadingCardProps {
    message?: string;
}
export default function RedirectUriLoader() {
    const user: any = useAuth()
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [code, setCode] = useState<string | null>(null);
    const retrieveYoutubeTokenReq = useApi(`/youtube/login/token`, user.token, false)

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        console.log(`URL Params: ${urlParams}`)
        const code = urlParams.get('code');
        console.log(`Code: ${code}`)

        if (!code) {
            setError('No authorization code found');
            return;
        }

        try {
            setCode(code);
        } catch (error) {
            setError('Error during authentication');
            console.error('Authentication error:', error);
        }

    }, []);

    useEffect(() => {
        if (code) {
            retrieveYoutubeTokenReq.setPayloadData({code: code})
        }
    }, [code]);

    useEffect(() => {
        if (!retrieveYoutubeTokenReq.loading && retrieveYoutubeTokenReq.state === apiStates.SUCCESS) {
            console.log('Token retrieved successfully');
            navigate('/')
        } else if (!retrieveYoutubeTokenReq.loading && retrieveYoutubeTokenReq.error) {
            console.error('Error retrieving token:', retrieveYoutubeTokenReq.data.error);
        }
    }, [retrieveYoutubeTokenReq.loading]);
    const LoadingCard: React.FC<LoadingCardProps> = ({ message = 'Loading...' }) => {
        return (
            <></>
        );
    };

    return (
        <></>
    );
}
