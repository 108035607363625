import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Flex,
    Image,
    Text,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
    useDisclosure,
    Divider
} from "@chakra-ui/react";
import { Chip, Pill } from "@mantine/core";
import { Podcast } from "../../models/Podcast";
import { useUser } from "../../context/UserContext";
import { usePodcastLibrary } from "../../context/PodcastLibraryContext";
import { useApi } from "../../api/braincap-api";
import { YoutubeChannelInfo } from "../../models/Youtube";
import PodcastDashboardPanel from "./podcast-dashboard-panel";
import { YoutubeChannelCard } from "../youtube/youtube-components-cards/youtube-components-cards";

export const PodcastCard = ({ podcast }: { podcast: Podcast }) => {
    const user: any = useUser();
    const {
        setCurrentPodcastId,
        setCurrentPodcast
    } = usePodcastLibrary();
    const youtubeChannelInfoReq = useApi(`/youtube/channel/info/${podcast.id}`, user.token, false);
    const [channelInfo, setChannelInfo] = useState<YoutubeChannelInfo>();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (!youtubeChannelInfoReq.loading && youtubeChannelInfoReq.data) {
            console.log(`Channel Info: ${JSON.stringify(youtubeChannelInfoReq.data)}`);
            setChannelInfo(youtubeChannelInfoReq.data);
        } else if (!youtubeChannelInfoReq.loading && youtubeChannelInfoReq.error) {
            toast({
                title: 'YouTube Channel Info',
                description: 'Error fetching YouTube Channel Info',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
        }
    }, [youtubeChannelInfoReq.loading]);

    const handleFetchRSS = () => {
        toast({
            title: 'Fetching RSS',
            description: 'Updating podcast information',
            status: 'info',
            duration: 5000,
            isClosable: true,
        });
    };

    return (
        <Flex w={'fit-content'} p="12px" flexDirection="column" alignItems="center" justifyContent={'flex-start'} bgColor={'#fff'} borderRadius="12px" gap={'8px'}
              boxShadow={'lg'}
              onClick={() => {
                  setCurrentPodcastId(podcast.id as string)
                  setCurrentPodcast(podcast)
              }
        }
        >
            <Flex w={'fit-content'} flexDirection="column" alignItems="flex-start" justifyContent="flex-start" gap="6px">
                <Link to={`/library/${podcast.id}`} key={podcast.id}>
                    <Image boxSize={'sm'} fallbackSrc='https://via.placeholder.com/150' src={podcast.image_url} borderRadius="8px" bgColor="gray.100"/>
                </Link>
                <Flex mt={'2px'} h={'full'} direction={'column'} alignItems={'flex-start'} justifyContent={'space-between'} gap={'8px'}>
                    <Link to={`/library/${podcast.id}`} key={podcast.id}>
                        <Text color="gray.800" textAlign="left" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                            {podcast.title}
                        </Text>
                    </Link>
                    <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                        <Tooltip label="Episodes" aria-label="Episodes">
                            <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                                <Text color="gray.500" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="16px">
                                    {podcast.episodes.length} Episodes
                                </Text>
                            </Flex>
                        </Tooltip>
                    </Flex>
                </Flex>
            </Flex>
            {channelInfo && <YoutubeChannelCard channel={channelInfo}/>}
            <PodcastDashboardPanel podcast={podcast} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
        </Flex>
    );
};
