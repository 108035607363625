import {Button, Flex, FormControl, FormErrorMessage, Input, Select, Spinner, Text} from "@chakra-ui/react";
import {MemberRole} from "../../models/Team";
import React, {useEffect, useState} from "react";
import {Publisher} from "../../models/Publisher";
import {useUser} from "../../context/UserContext";
import {UserCategory} from "../../models/Users";
import {NotificationSettings} from "../../models/BraincapNotification";
import {NotificationComponent} from "../notifications/NotificationComponent";

export const PublisherNotificationSettings = () => {
    const user: any = useUser()
    const [loading, setLoading] = React.useState<boolean>(true)
    const [notifications, setNotifications] = useState<NotificationSettings[]>([])

    useEffect(() => {
        if (!user.loading && user.user) {
            setNotifications(user.notificationsList)
            setLoading(false)
        }

    }, [user.loading])

    return (
        <Flex w={'full'} flexDirection="column" alignItems="flex-start" gap="24px">
            {loading && <Spinner alignSelf={'center'} size="lg" />}
            {!loading && user.user && user.user.category === UserCategory.PUBLISHER &&
                <Flex w={'full'} direction={'column'} gap={'24px'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                    {notifications.length > 0 && notifications.map((notification: NotificationSettings) => {
                        return (
                            <NotificationComponent key={notification.type} notification={notification}/>
                        )
                    })
                    }
            </Flex>
            }
        </Flex>
    )
}
