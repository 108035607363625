import apiClient, {PODCASTLIBRARYENDPOINTS} from "../api/braincap-api-client";
import {PodcastCartItem} from "../models/Cart";

export const CartService = {
    getCart: async (userId: string): Promise<PodcastCartItem[]> => {
        const response = await apiClient.get(`${PODCASTLIBRARYENDPOINTS.CART}/${userId}`);
        return response.data;
    },
    addToCart: async (data: { cart: PodcastCartItem[], publisherId: any }): Promise<any> => {
        const response = await apiClient.post(`${PODCASTLIBRARYENDPOINTS.ADD_TO_CART}/${data.publisherId}`, data);
        return response.data;
    },

    removeFromCart: async (data: { episodeId: string, publisherId: string }): Promise<any> => {
        const response = await apiClient.post(PODCASTLIBRARYENDPOINTS.REMOVE_FROM_CART, data);
        return response.data;
    },
};
