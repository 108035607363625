export interface AssemblyResponse {
    transcript_id: any,
    status: any
    language_code?: string
}

export interface RevResponse {
    status: 'in_progress' | 'completed' | 'processed' | 'failed' | 'other'
    last_updated_on?: any
    id?: string
    created_on?: any
    type?: string
    last_processed_on?: any
}

export enum AlignmentStatus {
    IN_PROGRESS = 'in_progress',
    COMPLETED = 'completed',
    PROCESSED = 'processed',
    FAILED = 'failed',
    NEED_TO_DOWNLOAD_VIDEO = 'need_to_download_video',
    OTHER = 'other'
}

export interface RevAlignmentSession {
    id: string
    status: 'in_progress' | 'completed' | 'failed' | 'other'
    created_on?: any
    completed_on?: any
}

export interface EpisodeStorageMeta {
    contentType: string
    language: string
    podcastId: string
    episodeId: string
    sessionId: string
    assemblyId: string
    created_at: any
}

export enum ProofreadTranscriptProcessor {
    BRAINCAP = 'braincap',
    REV = 'rev',
    THREEPLAY = 'threeplay'
}
