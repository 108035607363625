import React, {createContext, useContext, useEffect, useState} from "react";
import {ENDPOINTS, useApi} from "../api/braincap-api";
import {useAuth} from "./AuthContext";
import {User, UserCategory} from "../models/Users";
import {NotificationSettings} from "../models/BraincapNotification";
import {useToast} from "@chakra-ui/react";
import {TranscriptionSession} from "../models/Localization";
import PodcastLibrarySidebar from "../sidebars/podcast/podcast-library-sidebar";
import PodcastLibraryEpisodeSidebar from "../components/episode/podcast-library-episode-sidebar";

export const LibraryNavigationContext = createContext(null)

export enum PodcastLibrarySidebarRoutes {
    PODCAST = 'Podcast',
    EPISODES = 'Episode',
    SPEAKERS = 'Speakers',
    CATALOG = 'Catalog',
    TEAMS = 'Teams',
}

export default function LibraryNavigationProvider(props: any) {
    const auth: any = useAuth()
    const [route, setRoute] = useState<PodcastLibrarySidebarRoutes>(PodcastLibrarySidebarRoutes.PODCAST)
    const [displayLibrarySidebar, setDisplayLibrarySidebar] = useState<boolean>(false)
    const [sessions, setSessions] = useState<any>({})
    const [studioSession, setStudioSession] = useState<any>()
    const [currentPodcast, setCurrentPodcast] = useState<any>()
    const [currentEpisode, setCurrentEpisode] = useState<any>()

    const [sidebarContent, setSidebarContent] = useState<any>()

    useEffect(() => {
        if (!displayLibrarySidebar) {
            setCurrentEpisode(null)
        }
    }, [displayLibrarySidebar]);


    return (
        <LibraryNavigationContext.Provider value = {{
            route,
            sessions,
            currentPodcast,
            currentEpisode,
            displayLibrarySidebar,
            studioSession,
            sidebarContent,
            setRoute,
            setSessions,
            setCurrentPodcast,
            setStudioSession,
            setDisplaySidebar: setDisplayLibrarySidebar,
            setCurrentEpisode,
        }} {...props} />
    )
}

export const useLibraryNavigation = () => useContext(LibraryNavigationContext)



