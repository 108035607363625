import {Flex} from "@chakra-ui/react";
import React from "react";

import LibraryProvider from "../context/LibraryContext";
import ReviewerNavigationBar from "../components/reviewer/reviewer-navigation-bar";

interface Page {
    name: string
    children: React.ReactNode | React.ReactNode[],
}

export default function ReviewerLayout({name, children}: Page) {
    return (
        <LibraryProvider>
            <Flex minH={'100vh'} direction={'column'} justifyContent={'center'} bgColor={'gray.50'}>
                {/* Navbar with position sticky */}
                <Flex
                    w={'full'}
                    px={{md: '5%', lg: '10%' , xl: '15%'}}
                    justifyContent="center"
                    alignItems="center"
                    bg="white"
                    position="sticky"
                    top="0"
                    zIndex="1000">
                    <ReviewerNavigationBar page={name}/>
                </Flex>
                <Flex w={'full'} minH={'100vh'} px={{md: '5%', lg: '10%' , xl: '15%'}} justifyContent="center">
                    {children}
                </Flex>
            </Flex>
        </LibraryProvider>

    )
}
