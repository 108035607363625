import React, {useEffect, useState} from "react";
import {Flex, FormControl, FormErrorMessage, Radio, RadioGroup, Text} from "@chakra-ui/react";
import {BraincapService} from "../../models/Localization";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {useLocalization} from "../../context/LocalizationContext";

export const PublisherEditingConfiguration =  ({service, episode, validate}: {
    service: BraincapService,
    episode: PodcastEpisode
    validate: boolean
}) => {
    const [value, setValue] = useState('1')
    const localization: any = useLocalization()
    const [isInvalid, setIsInvalid] = useState<boolean>(false)

    useEffect(() => {
        let configs: any = {...localization.episodesConfiguration}
        let validations: any = {...localization.configurationValidation}
        let config: any = {...localization.episodesConfiguration[(episode as PodcastEpisode).id]};
        let validation: any = {...localization.configurationValidation[(episode as PodcastEpisode).id]};
        configs[(episode as PodcastEpisode).id] = {
            ...config,
            pre_translation_editing: value === 'Yes'
        }
        validations[(episode as PodcastEpisode).id] = {
            ...validation,
            publisher_editing: value === 'Yes' || value === 'No'
        }
        localization.setEpisodesConfiguration(configs)
        localization.setConfigurationValidation(validations)
        setIsInvalid(false)
    }, [value])

    useEffect(() => {
        setValue('1')
    }, [service])

    useEffect(() => {
        if (validate) {
            if (value === 'Yes' || value === 'No') {
                setIsInvalid(false)
            } else {
                setIsInvalid(true)
            }
        } else {
            setIsInvalid(false)
        }
    }, [validate])

    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Flex w="full" alignItems={'center'} justifyContent={'space-between'}>
                <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                    TRANSCRIPT EDITING
                </Text>
                {/*
                <Icon as={LuEdit2} boxSize={'24px'}/>
                */}
            </Flex>
            <Flex w={'full'} direction={'column'} gap={'16px'} alignItems={'flex-start'} justifyContent={'center'}>
                <Text color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                    Review & Edit Transcript before Translation
                </Text>
                <FormControl isRequired id={'publisher_editing'} isInvalid={isInvalid}>
                    <RadioGroup colorScheme={'teal'} onChange={setValue} value={value}>
                        <Flex color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px" direction='row' gap={'42px'}>
                            <Radio value='Yes'>Yes</Radio>
                            <Radio value='No'>No</Radio>
                        </Flex>
                    </RadioGroup>
                    <FormErrorMessage>Please select your editing configuration</FormErrorMessage>
                </FormControl>
            </Flex>
        </Flex>
    )
}
