import {PodcastYoutubePlaylist, YoutubeChannelInfo} from "../../../models/Youtube";
import {Card} from "@mantine/core";
import {Image} from "@mantine/core";

export const YoutubeChannelCard = ({channel}: {channel: YoutubeChannelInfo}) => {
    return (
        <Card shadow="xs" padding="md" radius="md">
            <Image src={channel.thumbnails.high.url} alt={channel.title} />
            <h2>{channel.title}</h2>
            <p>Subscribers: {channel.subscriberCount}</p>
            <p>Views: {channel.viewCount}</p>
        </Card>
    )
}

export const YoutubePlaylistCard = ({playlist}: {playlist: PodcastYoutubePlaylist}) => {
    return (
        <Card shadow="xs" padding="md" radius="md">
            <img src={playlist.thumbnails} alt={playlist.title} />
            <h2>{playlist.title}</h2>
            <p>{playlist.description}</p>
            <p>Published At: {playlist.publishedAt}</p>
        </Card>
    )
}
