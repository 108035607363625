import React, {PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {createEditor, CustomText, Editor, Node, Path, Point, Range, Text as SlateText, Transforms} from "slate";
import {FaBold, FaItalic, FaUnderline} from "react-icons/fa6";
import {withHistory} from "slate-history";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {Link} from "react-router-dom";
import {Editable, ReactEditor, Slate, useFocused, useSlate, withReact} from "slate-react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay, Avatar,
    Box,
    Button, ButtonGroup,
    Center,
    Divider,
    Flex, FocusLock, FormControl, FormLabel,
    Icon,
    IconButton,
    Image, Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger,
    Slide,
    Spinner, Stack,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import {RiPriceTag3Line, RiQuestionMark, RiQuoteText} from "react-icons/ri";
import {useUser} from "../../context/UserContext";
import {useAuth} from "../../context/AuthContext";
import {apiStates, useApi} from "../../api/braincap-api";
import {handleSplitParagraph} from "../editors/slate-helpers";
import LoadingSpinner from "../loading-spinner";
import {MdOutlineComment, MdOutlineSort} from "react-icons/md";
import {FiMaximize2, FiMinimize2} from "react-icons/fi";
import {useParams} from "react-router-dom";
import {PodcastTranscriptionTaskMeta, TranscriptionSession} from "../../models/Localization";
import AudioPlayer from "../player/components/AudioPlayer";
import {ShowCardSkeleton} from "../skeletons";
import {millisecondsToHHMMSS, secondsToHHMMSS} from "../../utils/firebase";
import {ActionFilter} from "../editors/transcription-helpers";

import {ChatIcon, CloseIcon, EditIcon, SmallCloseIcon} from "@chakra-ui/icons";
import {filterActionItems} from "../editors/timestamps-helpers";
import {
    CommentContentButton,
    getHighlightedTimestampsComments, isMarkActive,
    PopoverMenu, toggleFormatMark
} from "./reviewer-toolbar-buttons";
import {ToolbarButton, ToolbarMenu, ToolbarPortal} from "../editors/toolbar-helper";
import ReactPlayer from "react-player";
export const ReviewerEditor = () => {
    const user: any = useUser()
    const auth: any = useAuth()
    const { sessionId, podcastId, episodeId }: any = useParams();

    const editor = useMemo(() => withReact(withHistory(createEditor() as ReactEditor)), []);
    const [transcriptLoading, setTranscriptLoading] = useState<boolean>(true);
    const [transcriptSaving, setTranscriptSaving] = useState<boolean>(false);
    const [commentSaving, setCommentSaving] = useState<boolean>(false);
    const [fetchingComment, setFetchingComment] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [nodes, setNodes] = useState<Node[]>([]);
    const [task, setTask] = useState<TranscriptionSession>();
    const [value, setValue] = useState<Node[]>([]);
    const cancelRef: any = React.useRef()
    const [actions, setActions] = useState<any[]>([]);
    const [filteredActions, setFilteredActions] = useState<any[]>([]);
    const [openActionBox, setOpenActionBox] = useState<boolean>(true);
    const transcriptReq = useApi(`/reviewer/transcript/get/${sessionId}/${podcastId}/${episodeId}`, user.token, false);
    const transcriptSaveReq = useApi(`/reviewer/transcript/save/${sessionId}/${podcastId}/${episodeId}`, user.token, false);
    const transcriptSubmitReq = useApi(`/reviewer/transcript/submit/${sessionId}/${podcastId}/${episodeId}`, user.token, false);
    const { onOpen: customOnOpen, onClose: customOnClose, isOpen: customIsOpen } = useDisclosure();
    const toast = useToast();
    const transcriptRef: any = useRef(null);
    const { onOpen: submitOnOpen, onClose: submitOnClose, isOpen: submitIsOpen } = useDisclosure();
    const { onOpen: actionOnOpen, onClose: actionOnClose, isOpen: actionIsOpen } = useDisclosure();
    const [proposals, setProposal] = useState<any[]>([])
    const [editingLeaf, setEditingLeaf] = useState<any>()
    const [editingElement, setEditingElement] = useState<any>()

    const ref: any = useRef<HTMLDivElement | null>()

    useEffect(() => {
        if (!user.loading && !!user.user) {
            setTranscriptLoading(true)
            transcriptReq.setPayloadData({})
        }
    }, [user.loading])

    useEffect(() => {
        if (!transcriptReq.loading && !!transcriptReq.data ) {
            setTask(transcriptReq.data.session)
            setValue(transcriptReq.data.transcript)
            //library.setNavBarEpisodeTitle(transcriptReq.data.session.episode.title)
            //library.setOverrideNavBarEpisodeTitle(true)
            //setActions(transcriptReq.data.actions)
            setTranscriptLoading(false)
        }
    }, [transcriptReq.loading, transcriptReq.data])

    useEffect(() => {
        setFilteredActions(actions)
    }, [actions])

    useEffect(() => {
        if (value.length > 0) {
            let newActions: any = filterActionItems(value)
            setProposal(newActions)
        }
    }, [value])

    useEffect(() => {
        if (!transcriptSaveReq.loading && transcriptSaveReq.state === apiStates.SUCCESS) {
            toast({
                title: 'Success',
                description: 'Transcript saved successfully',
                status: 'success',
                duration: 1000,
                isClosable: true,
                position: 'top-right'

            })
            setActions(transcriptSaveReq.data.actions)
            setTranscriptSaving(false)
        }
        if (!transcriptSaveReq.loading && transcriptSaveReq.error) {
            toast({
                title: 'Error',
                description: 'Could not save transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
            setTranscriptSaving(false)
        }
    }, [transcriptSaveReq.loading, transcriptSaveReq.data])

    useEffect(() => {
        if (!transcriptSubmitReq.loading && transcriptSubmitReq.state === apiStates.SUCCESS) {
            toast({
                title: 'Transcript Submitted',
                description: 'Transcript submitted successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            deleteLocalTranscript()
            window.location.href = '/dashboard'
        }

        if (!transcriptSubmitReq.loading && transcriptSubmitReq.error) {
            saveLocalTranscript(value)
            toast({
                title: 'Error',
                description: `Could not submit transcript. Local copy saved. Please try again or contact support.`,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        setSubmitting(false)
    }, [transcriptSubmitReq.loading, transcriptSubmitReq.data])

    const renderElement = useCallback(({...props}) => {
        let list = props.element.speakersList
        switch (props.element.type) {
            case 'timedText':
                return (
                    <Flex direction={'column'} justifyContent={'flex-start'} gap={'12px'} alignItems={'flex-start'} w={'full'} {...props.attributes}>
                        <Menu>
                            <MenuButton userSelect={'none'}>
                                <Text contentEditable={false} color="teal.600" fontSize="14px" fontWeight="600" lineHeight="16px">
                                    {props.element.speaker}
                                </Text>
                            </MenuButton>
                            <MenuList>
                                {list.map((speaker: any) => {
                                    return (
                                        <MenuItem contentEditable={false}>
                                            {speaker.name}
                                        </MenuItem>
                                    )
                                })}
                            </MenuList>
                        </Menu>
                        <Text color="gray.900" fontSize="16px" fontWeight="400" lineHeight="20px">
                            {props.children}
                        </Text>
                        <br/>
                    </Flex>
                )
            default:
                return <DefaultElement {...props} />;

        }
    }, []);

    const renderLeaf = useCallback(({ attributes, children, leaf }: { attributes: any, children: any, leaf: any }) => {
        const extendedAttributes = {
            ...attributes,
            'data-start': leaf.start,
            'data-end': leaf.end
        };

        if (leaf.isAdvertisement) {
            if (leaf.isAction || leaf.isComment) {
                children = (
                    <span style={{
                        backgroundColor: '#E6FFFA',
                        color: '#2B6CB0'
                    }}>
                {children}
            </span>
                );
            } else {
                children = <span style={{ backgroundColor: '#FFFFF0', color: '#B7791F' }}>{children}</span>;
            }
        } else if (leaf.isInaudibleContent) {
            if (leaf.isAction || leaf.isComment) {
                children = (
                    <span style={{
                        backgroundColor: '#EBF8FF',
                        color: '#ffa500'
                    }}>
                {children}
            </span>
                );
            } else {
                children = <span style={{ backgroundColor: '#FFF5F5', color: '#C53030'}}>{children}</span>;
            }
        } else {
            children = <span style={{ backgroundColor: 'transparent' }}>{children}</span>;
        }

        if (leaf.isComment) {
            children = <span onClick={customOnOpen} style={{ backgroundColor: '#EBF8FF', color: '#2B6CB0' }}>{children}</span>;
        } else if (leaf.isComment && leaf.isAction) {
            children = (
                <>
                    <span onClick={customOnOpen} style={{ backgroundColor: '#E6FFFA', color: '#319795' }}>
                {children}
            </span>
                </>
            )
        } else if (leaf.isAction) {
            children = (
                <>
                <span  onClick={customOnOpen} style={{ backgroundColor: '#E6FFFA', color: '#319795'  }}>
                {children}
            </span>
                </>
            )

        }

        if (leaf.highlight) {
            children = <span style={{ backgroundColor: '#FDE047' }}>{children}</span>;
        }
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underlined) {
            children = <u>{children}</u>
        }

        return <span {...extendedAttributes}>{children}</span>;
    }, []);

    const scrollToRange = (range: any) => {
        console.log('Attempting to scroll to range:', range);
        if (!transcriptRef.current) {
            console.error('transcriptRef.current is not available.');
            return;
        }
        if (!editor.selection) {
            console.error('Editor selection is not available.');
            return;
        }

        try {
            const domRange = ReactEditor.toDOMRange(editor, range);
            console.log('DOM Range:', domRange);

            const rect = domRange.getBoundingClientRect();
            console.log('DOM Range rect:', rect);

            const transcriptRect = transcriptRef.current.getBoundingClientRect();
            console.log('Transcript container rect:', transcriptRect);

            const currentScrollTop = transcriptRef.current.scrollTop;
            console.log('Current scroll top:', currentScrollTop);

            const scrollToPosition = rect.top - transcriptRect.top + currentScrollTop;
            console.log('Calculated scroll to position:', scrollToPosition);

            transcriptRef.current.scrollTo({
                top: scrollToPosition,
                behavior: 'smooth'
            });

            console.log('Scroll action initiated.');
        } catch (e) {
            console.error('Error while scrolling to range:', e);
        }
    };



    const handleSetSpeaker = (props: any, name: string) => {
        const pathToCurrentNode = ReactEditor.findPath(editor, props.element);
        const oldSpeakerName = props.element.speaker;
        const isUpdateAllSpeakerInstances = window.confirm(`Would you like to replace all occurrences of ${oldSpeakerName} with ${name}?`);

        if (isUpdateAllSpeakerInstances) {
            const rangeForTheWholeEditor = Editor.range(editor, []);
            Transforms.setNodes(
                editor,
                { type: 'timedText', speaker: name } as Partial<Node>,
                {
                    at: rangeForTheWholeEditor,
                    match: (node: any) => node.type === 'timedText' && node.speaker.toLowerCase() === oldSpeakerName.toLowerCase(),
                }
            );
        } else {
            Transforms.setNodes(editor, {type: 'timedText', speaker: name} as Partial<Node>, { at: pathToCurrentNode })
        }
    }

    const DefaultElement = ({...props}) => {
        return (
            <>
                <p {...props.attributes}>{props.children}</p>
                <br/>
            </>
        );
    };

    const handleChange = (data: any) => {
        setValue(data);
        const isAstChange = editor.operations.some(
            op => 'set_selection' !== op.type
        )
        if (isAstChange) {
            // Save the v5alue to Local Storage.
            if (!transcriptSaving) {
                setTranscriptSaving(true)
                let finalValue = removeWordsParamForChildren(value)
                transcriptSaveReq.setPayloadData({transcript: finalValue})

            }
        }
    };

    const saveLocalTranscript = (data: any) => {
        const isAstChange = editor.operations.some(
            op => 'set_selection' !== op.type
        )
        if (isAstChange) {
            // Save the value to Local Storage.
            const content = JSON.stringify(data)
            localStorage.setItem(`review-${user.userId}-${podcastId}-${episodeId}`, JSON.stringify(content))
        }
    }

    const deleteLocalTranscript = () => {
        localStorage.removeItem(`${user.userId}-${podcastId}-${episodeId}`)
    }

    const handleOnKeyDown = (event: any) => {
        if (event.key === '&') {
            // Prevent the ampersand character from being inserted.
            event.preventDefault()
            // Execute the `insertText` method when the event occurs.
            editor.insertText('and')
        }

        //handle ctrl + z
        if (event.ctrlKey && event.key === 'z') {
            event.preventDefault()
            editor.undo()
        }

        if (event.key === 'Enter') {
            // intercept Enter, and handle timecodes when splitting a paragraph
            event.preventDefault();
            // console.info('For now disabling enter key to split a paragraph, while figuring out the aligment issue');
            // handleSetPauseWhileTyping();
            // TODO: Edge case, hit enters after having typed some other words?
            handleSplitParagraph(editor);
        }
    }

    const HoveringToolbar = () => {
        const ref2: any = useRef<HTMLDivElement | null>()
        const popOverRef: any = useRef<HTMLDivElement | null>()
        const editor = useSlate()
        const inFocus = useFocused()

        useEffect(() => {
            try {
                const el = ref.current
                const popOverEl = popOverRef.current
                const { selection } = editor
                if (!el) {
                    return
                }

                if (
                    !selection ||
                    !inFocus ||
                    Range.isCollapsed(selection) ||
                    Editor.string(editor, selection) === ''
                ) {
                    el.removeAttribute('style')
                    return
                }

                const domSelection: any = window.getSelection()
                const domRange = domSelection.getRangeAt(0)
                const rect = domRange.getBoundingClientRect();
                el.style.opacity = '1';
                el.style.top = `${rect.bottom + window.scrollY}px`;
                el.style.left = `${rect.left + window.scrollX + (rect.width / 2) - (el.offsetWidth / 2)}px`// <-- Call the function here
            } catch (e) {
                console.log(e)
            }

        } )

        return (
            <ToolbarPortal  ref={ref}>
                <ToolbarMenu
                    ref={ref}
                    onMouseDown={(e: any) => {e.preventDefault()}}
                >
                    <CommentContentButton
                        format={'isComment'}
                        icon={<MdOutlineComment style={{color: '#2B6CB0',}}/>}
                        name={'Add Review'}
                        activeName={'View Review'}
                        editor={editor}
                        customOnOpen={customOnOpen}
                    />
                    <FormatButton format="bold" icon={<FaBold />} />
                    <FormatButton format="italic" icon={<FaItalic />} />
                    <FormatButton format="underlined" icon={<FaUnderline />} />
                </ToolbarMenu>
            </ToolbarPortal>
        )
    }

    const FormatButton = ({ format, icon }: { format: any, icon: any}) => {
        const editor = useSlate()
        let data = getHighlightedTimestamps(editor)
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, format)}
                onClick={() => toggleFormatMark(editor, format, data?.startTime, data?.endTime)}
            >
                <Icon>{icon}</Icon>
            </ToolbarButton>
        )
    }

    const getHighlightedTimestamps = (editor: any) => {
        const { selection } = editor;

        if (!selection || Range.isCollapsed(selection)) return;

        const isReversed = Point.isAfter(selection.anchor, selection.focus);

        const textNodes = Array.from(
            Editor.nodes(editor, {
                at: selection,
                match: n => SlateText.isText(n),
            })
        );

        if (!textNodes.length) return;

        const [firstTextNode, firstPath] = textNodes[0];
        const [lastTextNode, lastPath] = textNodes[textNodes.length - 1];

        const selectedText = Editor.string(editor, selection);

        return { startTime: 'NaN', endTime: 'NaN', text: selectedText };
    };

    const findWordForTextNode = (textNode: any, path: any, editor: any, offset: number) => {
        const [parentNode]: any = Editor.parent(editor, path);

        if (!parentNode.children || !parentNode.children[0].words) return null;

        const wordsArray = parentNode.children[0].words;

        // Split the textNode text into individual words
        const textNodeWords = textNode.text.split(/\s+/);

        // Determine which word the offset falls within
        let currentLength = 0;
        let targetWord: any = null;
        for (let word of textNodeWords) {
            currentLength += word.length + 1; // +1 for the space
            if (currentLength >= offset) {
                targetWord = word;
                break;
            }
        }

        if (!targetWord) return null;

        // Find the corresponding word object for the target word
        const wordObj = wordsArray.find((word: any) => word.text === targetWord);

        return wordObj;
    }

    let image_url = "https://megaphone.imgix.net/podcasts/042e6144-725e-11ec-a75d-c38f702aecad/image/Huberman-Lab-Podcast-Thumbnail-3000x3000.png?ixlib=rails-4.3.1&max-w=3000&max-h=3000&fit=crop&auto=format,compress"

    useEffect(() => {
        // Function to be executed after 2 minutes
        const timer = setInterval(() => {
            if (!transcriptSaving && !submitting && !transcriptLoading && value.length > 0) {
                saveLocalTranscript(value)
            }
        }, 60000); // 120,000 milliseconds = 2 minutes

        // Cleanup function
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        // Function to be executed after 2 minutes
        const timer = setInterval(() => {
            if (!transcriptSaving && !submitting && !transcriptLoading && value.length > 0) {
                setTranscriptSaving(true)
                transcriptSaveReq.setPayloadData({transcript: value})
            }
        }, 60000); // 120,000 milliseconds = 2 minutes

        // Cleanup function
        return () => clearInterval(timer);
    }, []);

    const removeWordsParamForChildren = (transcript: any) => {
        let newTranscript = transcript.map((paragraph: any) => {
            let newParagraph = { ...paragraph };
            newParagraph.children = paragraph.children.map((children: any) => {
                let newChildren = { ...children };
                delete newChildren.words;
                return newChildren;
            });
            return newParagraph;
        });
        return newTranscript;
    }

    return (
        <Flex w={'full'} direction="row" p={'0px'} gap={'32px'}>
            <Flex w={'100%'} p={'20px'} bgColor={'white'} boxShadow={'md'} borderRadius={12}>
                <Flex direction={'row'} alignItems={'center'} justifyContent={'space-between'} ref={transcriptRef} w={'full'} h={'full'} style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                    {transcriptLoading?
                        <LoadingSpinner/>
                        :
                        <Flex direction={'column'} gap={'10px'} align={'center'} justify={'center'} w={'100%'} h={'100%'}>
                            <Slate
                                editor={editor}
                                onChange={handleChange}
                                value={value}>
                                <HoveringToolbar />
                                <Editable
                                    renderElement={renderElement}
                                    readOnly={false}
                                    renderLeaf={renderLeaf}
                                    onKeyDown={handleOnKeyDown}
                                    autoFocus
                                    style={{ height: '100%', overflowY: 'scroll' }}
                                />
                            </Slate>
                        </Flex>
                    }
                </Flex>
            </Flex>
            <PopoverMenu user={user.user} editor={editor} isOpen={customIsOpen} onOpen={customOnOpen} onClose={customOnClose} />
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={submitOnClose}
                isOpen={submitIsOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>Submit task?</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody w={'full'} justifyContent={'center'} alignItems={'center'}>
                        {submitting?
                            <Flex w={'full'} justifyContent={'center'} alignItems={'center'}>
                                <Spinner alignSelf={'center'} color={'teal'}/>
                            </Flex>
                            :
                            <Text>
                                Are you sure you want to submit this task? You will not be able to edit it after submission.
                            </Text>
                        }
                        <Input variant={'filled'} size={'sm'} placeholder={'Add a comment'}/>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={submitOnClose}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3} onClick={() => {
                            setSubmitting(true)
                            transcriptSubmitReq.setPayloadData({transcript: value, labeler: user.user})
                        }}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <Slide in={true} direction='bottom' style={{ zIndex: 10 }}>
                <Box pos={'fixed'} justifyContent={'center'} alignItems="center" bottom="0" bg="white" boxShadow={'lg'} py={'14px'} h={20} w="100%" zIndex={2}>
                    <Center justifyContent={'center'} alignItems={'center'} w={'full'} gap={'36px'}>
                        <Button w={'30%'} variant={'solid'} size= "md" colorScheme="teal" onClick={submitOnOpen}>
                            Submit
                        </Button>
                        <Button
                            w={'30%'}
                            onClick={() => {
                                if (!transcriptSaving) {
                                    setTranscriptSaving(true)
                                    let finalValue = removeWordsParamForChildren(value)
                                    transcriptSaveReq.setPayloadData({transcript: finalValue})
                                } else {
                                    toast({
                                        title: "Transcript is already saving",
                                        description: "Please wait until the transcript is saved",
                                        status: "warning",
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                }
                            }}
                            variant={'outline'}
                            size= "md"

                            colorScheme="teal">
                            Save
                        </Button>
                    </Center>
                </Box>
            </Slide>
        </Flex>

    );
}
