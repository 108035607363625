import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useUser } from './UserContext';
import { useAuth } from './AuthContext';
import {
    SupportedLanguages,
    Subscription,
    AppConfig
} from '../models/AppConfig';
import {
    PodcastCartItem,
    MediaContentCartItem
} from '../models/Cart';
import { ConfigurationValidation } from "./LocalizationContext";
import { BraincapService } from "../models/Localization";
import { useAppConfig } from '../hooks/useLocalizationQueries';
import { useAddToCart } from '../hooks/useCartQueries';
import { useToast } from '@chakra-ui/react';

interface PodcastLocalizationContextType {
    services: Map<string, BraincapService>;
    supportedLanguages: SupportedLanguages | undefined;
    episodesConfiguration: Map<string, Partial<PodcastCartItem>>;
    setEpisodesConfiguration: (data: Map<string, Partial<PodcastCartItem>>) => void;
    mediaContentConfiguration: Map<string, Partial<MediaContentCartItem>>;
    setMediaContentConfiguration: (data: Map<string, Partial<MediaContentCartItem>>) => void;
    configurationValidation: Map<string, ConfigurationValidation>;
    setConfigurationValidation: React.Dispatch<React.SetStateAction<Map<string, ConfigurationValidation>>>;
    subscriptions: Subscription[];
    handleAddToCart: () => void;
    validateConfiguration: () => boolean;
    resetConfiguration: () => void;
    getService: (type: string) => BraincapService | undefined;
    isConfigurationValid: boolean;
    addEpisodeToConfiguration: (episodeId: string, episodeData: Partial<PodcastCartItem>) => void;
    removeEpisodeFromConfiguration: (episodeId: string) => void;
    updateEpisodeConfiguration: (episodeId: string, updates: Partial<PodcastCartItem>) => void;
}

const PodcastLocalizationContext = createContext<PodcastLocalizationContextType | null>(null);

export const PodcastLocalizationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const user: any = useUser();
    const auth: any = useAuth();
    const toast = useToast();
    const [isReady, setIsReady] = useState(false);

    const { data: appConfig, isLoading: isAppConfigLoading } = useAppConfig();
    const { mutate: addItemsToCart } = useAddToCart();

    const [episodesConfiguration, setEpisodesConfiguration] = useState<Map<string, Partial<PodcastCartItem>>>(new Map());
    const [mediaContentConfiguration, setMediaContentConfiguration] = useState<Map<string, Partial<MediaContentCartItem>>>(new Map());
    const [configurationValidation, setConfigurationValidation] = useState<Map<string, ConfigurationValidation>>(new Map());

    useEffect(() => {
        if (!auth.loading && !user.loading && user.user && !isAppConfigLoading) {
            setIsReady(true);
        }
    }, [auth.loading, user.loading, user.user, isAppConfigLoading]);

    const services = useMemo(() => {
        const serviceMap = new Map<string, BraincapService>();
        if (appConfig && user.user?.subscription) {
            const subscriptionType = user.user.subscription.type;
            appConfig.services[subscriptionType]?.forEach((service: BraincapService) => {
                serviceMap.set(service.type, service);
            });
        }
        return serviceMap;
    }, [appConfig, user.user?.subscription]);

    const validateConfiguration = useCallback((): boolean => {
        let isValid = true;
        const newValidation = new Map<string, ConfigurationValidation>();

        episodesConfiguration.forEach((config, episodeId) => {

        });

        setConfigurationValidation(newValidation);
        return isValid;
    }, [episodesConfiguration]);

    const handleAddToCart = useCallback(() => {
        if (validateConfiguration()) {
            const cartItems = Array.from(episodesConfiguration.values()) as PodcastCartItem[];
            addItemsToCart(
                {
                    cart: cartItems,
                    publisherId: user.user.userId
                },
                {
                    onSuccess: () => {
                        toast({
                            title: "Added to cart",
                            description: "Episodes have been added to your cart",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                        resetConfiguration();
                    },
                    onError: (error) => {
                        toast({
                            title: "Error",
                            description: "Failed to add items to cart. Please try again.",
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }
            );
        } else {
            toast({
                title: "Invalid Configuration",
                description: "Please correct the errors in your configuration",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }, [validateConfiguration, episodesConfiguration, addItemsToCart, user.user, toast]);

    const resetConfiguration = useCallback(() => {
        setEpisodesConfiguration(new Map());
        setMediaContentConfiguration(new Map());
        setConfigurationValidation(new Map());
    }, []);

    const getService = useCallback((type: string): BraincapService | undefined => {
        return services.get(type);
    }, [services]);

    const addEpisodeToConfiguration = useCallback((episodeId: string, episodeData: Partial<PodcastCartItem>) => {
        setEpisodesConfiguration(prev => {
            const newConfig = new Map(prev);
            newConfig.set(episodeId, episodeData);
            return newConfig;
        });
    }, []);

    const removeEpisodeFromConfiguration = useCallback((episodeId: string) => {
        setEpisodesConfiguration(prev => {
            const newConfig = new Map(prev);
            newConfig.delete(episodeId);
            return newConfig;
        });
    }, []);

    const updateEpisodeConfiguration = useCallback((episodeId: string, updates: Partial<PodcastCartItem>) => {
        setEpisodesConfiguration(prev => {
            const newConfig = new Map(prev);
            const existingConfig = newConfig.get(episodeId);
            if (existingConfig) {
                newConfig.set(episodeId, { ...existingConfig, ...updates });
            }
            return newConfig;
        });
    }, []);

    const isConfigurationValid = useMemo(() => {
        return false;
    }, [configurationValidation]);

    const value = useMemo(() => ({
        services,
        supportedLanguages: appConfig?.languages,
        episodesConfiguration,
        setEpisodesConfiguration: (data: Map<string, Partial<PodcastCartItem>>) => setEpisodesConfiguration(new Map(data)),
        mediaContentConfiguration,
        setMediaContentConfiguration: (data: Map<string, Partial<MediaContentCartItem>>) => setMediaContentConfiguration(new Map(data)),
        configurationValidation,
        setConfigurationValidation,
        subscriptions: appConfig?.subscriptions || [],
        handleAddToCart,
        validateConfiguration,
        resetConfiguration,
        getService,
        isConfigurationValid,
        addEpisodeToConfiguration,
        removeEpisodeFromConfiguration,
        updateEpisodeConfiguration,
    }), [
        services,
        appConfig,
        episodesConfiguration,
        mediaContentConfiguration,
        configurationValidation,
        handleAddToCart,
        validateConfiguration,
        resetConfiguration,
        getService,
        isConfigurationValid,
        addEpisodeToConfiguration,
        removeEpisodeFromConfiguration,
        updateEpisodeConfiguration,
    ]);

    if (!isReady) {
        return null; // or a loading spinner
    }

    return (
        <PodcastLocalizationContext.Provider value={value}>
            {children}
        </PodcastLocalizationContext.Provider>
    );
};

export const usePodcastLocalization = () => {
    const context = useContext(PodcastLocalizationContext);
    if (!context) {
        throw new Error('usePodcastLocalization must be used within a PodcastLocalizationProvider');
    }
    return context;
};
