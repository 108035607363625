import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {EpisodeModule} from "../../components/episode/episode-module";
import CartPanel from "../../components/cart/cart-panel";
import {RssLocalizationProgress} from "../../components/progress-components";
import {SegmentedControl, Flex, Text} from "@mantine/core";
import {PodcastPostProductionLibrary} from "./PodcastPostProductionLibrary";

export default function PodcastDashboard() {
    const { podcastId } = useParams<{ podcastId: string }>();


    if (!podcastId) {
        return <div>Loading...</div>;
    }

    return (
        <EpisodeModule podcastId={podcastId}/>
    );
}
