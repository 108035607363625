import {LocalizationService} from "../services/localization-service";
import {useQuery} from "@tanstack/react-query";

export const useAppConfig = () => {
    return useQuery({
        queryKey: ['appConfig'],
        queryFn: LocalizationService.getAppConfig,
        enabled: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
        refetchOnWindowFocus: false,
    });
};

export const useGetTranscriptionSessions = (podcastId: string) => {
    return useQuery({
        queryKey: ['transcriptionSessions', podcastId],
        queryFn: () => LocalizationService.getTranscriptionSessions(podcastId),
        enabled: !!podcastId && podcastId !== '',
        staleTime: 5 * 60 * 1000, // 5 minutes
        refetchOnWindowFocus: false,
    });
}
