import {Language} from "./AppConfig";
import {EpisodeLocalizationMeta} from "./PodcastEpisode";

export interface Transcript {
    id?: string,
    language?: Language,
    title: TextData,
    description: TextData,
    content: Paragraph[],
}

export interface Maktub {
    id?: string,
    created_at: string,
    source_language: Language,
    target_languages: Language[],
    episode: Pick<EpisodeLocalizationMeta, 'id' | 'title' | 'duration' | 'description' | 'URL' | 'image_url'>,
}

export interface MaktubPayload {
    maktub: Maktub,
    transcripts: Transcript[],
}
export interface TranscriptData {
    words: SlateWord[],
    paragraphs: SlateParagraph[]
}

export interface SlateWord {
    id: string,
    text: string,
    start: number,
    end: number,
}

export interface SlateParagraph {
    id: string,
    text: string,
    start: number,
    end: number,
    speaker: string,
}

export interface Paragraph {
    id?: string,
    language: Language
    speaker: string,
    text: string,
    start: number,
    end: number,
}

export interface TextData {
    language: Language,
    text: string,
}

export interface ThreePlayMediaParagraph {
    id: number;
    speaker: string;
    text: string;
    startTime: number;
    endTime: number;
}

export interface ThreePlayMediaTranscript {
    speakers: Record<string, string>;
    words: Array<[string, string]>;
}

export interface TranscriptSource {
    ASSEMBLY_AI: "ASSEMBLY_AI",
    THREE_PLAY_MEDIA: "THREE_PLAY_MEDIA",
}

export enum TranscriptMode {
    EDITING = 'EDITING',
    REVIEWING = 'REVIEWING',
    COMPLETED = 'COMPLETED',
    ASR = 'ASR',
    PROOFREAD = 'PROOFREAD',
}
