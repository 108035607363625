import {createContext, useContext, useEffect, useState} from "react";
import {useUser} from "./UserContext";
import {ENDPOINTS, useApi} from "../api/braincap-api";
import {Podcast} from "../models/Podcast";
import {LocalizedEpisode, PodcastEpisode} from "../models/PodcastEpisode";
import {PaymentStatus, PodcastCartItem} from "../models/Cart";
import {MediaContent} from "../models/MediaContent";
import {UserCategory} from "../models/Users";

export const LibraryContext = createContext(null);

export default function LibraryProvider (props: any) {
    const user: any = useUser()
    const [podcasts, setPodcasts] = useState<Map<string,Podcast>>(new Map<string,Podcast>())
    const [episodes, setEpisodes] = useState<any>({})
    const [episodeInReview, setEpisodeInReview] = useState<PodcastEpisode[]>([])
    const [mediaInProduction, setMediaInProduction] = useState<Map<string, MediaContent[]>>(new Map<string, MediaContent[]>())
    const [podcastEpisodes, setPodcastEpisodes] = useState<Map<string, PodcastEpisode[]>>(new Map<string, PodcastEpisode[]>())
    const [localizedEpisodes, setLocalizedEpisodes] = useState<Map<string, LocalizedEpisode[]>>(new Map<string, LocalizedEpisode[]>())
    const [podcastLoading, setPodcastLoading] = useState<boolean>(false)
    const [podcastsDownloaded, setPodcastsDownloaded] = useState<boolean>(false)
    const [downloadPodcast, setDownloadPodcast] = useState<boolean>(false)
    const [episodesLoading, setEpisodesLoading] = useState<boolean>(false)
    const [reviewEpisodesLoading, setReviewEpisodesLoading] = useState<boolean>(false)
    const [mediaInProductionLoading, setMediaInProductionLoading] = useState<boolean>(false)
    const [episodesDownloaded, setEpisodesDownloaded] = useState<boolean>(false)
    const [mediaInProductionDownloaded, setMediaInProductionDownloaded] = useState<boolean>(false)
    const [languagesProgress, setLanguagesProgress] = useState<any>()
    const [episodeTranscriptionProgress, setEpisodeTranscriptionProgress] = useState<any>()
    const [cart, setCart] = useState<PodcastCartItem[]>([])
    const [cartMap, setCartMap] = useState<Map<string, PodcastCartItem>>(new Map<string, PodcastCartItem>())
    const [cartLoading, setCartLoading] = useState<boolean>(false)
    const [cartDownloaded, setCartDownloaded] = useState<boolean>(false)
    const [timerForRedownload, setTimerForRedownload] = useState<boolean>(false)
    const [navBarEpisodeTitle, setNavBarEpisodeTitle] = useState<string>('')
    const [overrideNavBarEpisodeTitle, setOverrideNavBarEpisodeTitle] = useState<boolean>(false)

    const [fetchRss, setFetchRss] = useState(false);
    const [rssLink, setRssLink] = useState<string>('')
    const [rssLoading, setRssLoading] = useState(false);

    const currentCartReq = useApi(ENDPOINTS.CART_GET.concat(`${user.userId}`), user.token, false)
    const podcastsReq = useApi(ENDPOINTS.PUBLISHER_GET_PODCASTS.concat(`${user.userId}`), user.token, false)
    const episodesReq = useApi(ENDPOINTS.PUBLISHER_GET_EPISODES.concat(`${user.userId}`), user.token, false)
    const episodesInReviewReq = useApi('/publisher/review/get/DeJywyienIQBwckWsjOm3JdMH3t1', user.token, false)
    const mediaInProductionReq = useApi(`/publisher/mediaContent/${user.userId}`, user.token, false)
    const fetchRssReq = useApi(ENDPOINTS.FETCH_RSS, user.token, false)

    useEffect(() => {
        if (!!user.userId && user.user.has_uploaded_content && !podcastsDownloaded) {
            console.log('fetching podcasts')
            setPodcastLoading(true)
            //podcastsReq.execute()
        }

    }, [user.userId])

    useEffect(() => {
        if (!!user.userId && downloadPodcast) {
            console.log('fetching podcasts')
            setPodcastLoading(true)
            podcastsReq.execute()
        }
    }, [downloadPodcast])

    useEffect(() => {
        if (timerForRedownload) {
            //launch a 10 seconds timer to redownload podcasts
            setTimeout(() => {
                setDownloadPodcast(true)
            }, 10000)
        }
    }, [setTimerForRedownload])

    useEffect(() => {
        if (!podcastsReq.loading && podcastsReq.data) {
            console.log('podcasts fetched')
            let current = podcasts
            podcastsReq.data.forEach((podcast: Podcast) => {
                current.set(podcast.id as string, podcast)
            })
            setPodcasts(current)
            setPodcastLoading(false)
            setDownloadPodcast(false)
            setPodcastsDownloaded(true)
        }
    }, [podcastsReq.loading])

    useEffect(() => {
        if (!!user.userId && cartDownloaded) {
            setEpisodesLoading(true)
            episodesReq.execute()
        }
    }, [cartDownloaded])

    useEffect(() => {
        let result: any = {}
        if (!episodesReq.loading && episodesReq.data) {
            let current = podcastEpisodes
            //let currentLocalized = localizedEpisodes
            episodesReq.data.episodes.forEach((episode: PodcastEpisode) => {
                result = {...result, [episode.id as string]: episode}
                if (current.has(episode.podcast.id as string)) {
                    current.set(episode.podcast.id as string, [...current.get(episode.podcast.id as string) as PodcastEpisode[], episode])
                } else {
                    current.set(episode.podcast.id as string, [episode])
                }
            })

            setEpisodes(result)
            setPodcastEpisodes(current)
            setLanguagesProgress(episodesReq.data.progress)
            setEpisodeTranscriptionProgress(episodesReq.data.transcriptionProgress)
            setReviewEpisodesLoading(true)
            episodesInReviewReq.execute()
            setEpisodesDownloaded(true)
            setEpisodesLoading(false)


        } else if (!episodesReq.loading && episodesReq.error) {
            console.log(`LIBRARY_CONTEXT -> Unable to fetch episodes for publisher: ${user.userId}`)
        }
    }, [episodesReq.loading])

    useEffect(() => {
        if (!episodesInReviewReq.loading && episodesInReviewReq.data) {
            if (user.user.category === UserCategory.PUBLISHER) {
                mediaInProductionReq.execute()
                setMediaInProductionLoading(true)
            }
            setEpisodeInReview(episodesInReviewReq.data)
            setReviewEpisodesLoading(false)
        } else if (!episodesInReviewReq.loading && episodesInReviewReq.error) {
            console.log(`LIBRARY_CONTEXT -> Unable to fetch episodes for publisher: ${user.userId}`)
        }
    }, [episodesInReviewReq.loading])

    useEffect(() => {
        if (!mediaInProductionReq.loading && mediaInProductionReq.data) {
            let current = mediaInProduction
            mediaInProductionReq.data.forEach((media: MediaContent) => {
                if (current.has(media.id as string)) {
                    current.set(media.id as string, [...current.get(media.id as string) as MediaContent[], media])
                } else {
                    current.set(media.id as string, [media])
                }
            })
            console.log(`LIBRARY_CONTEXT -> Media in production: ${JSON.stringify(current)}`)
            setMediaInProduction(current)
            setMediaInProductionDownloaded(true)
            setMediaInProductionLoading(false)
        } else if (!mediaInProductionReq.loading && mediaInProductionReq.error) {
            console.log(`LIBRARY_CONTEXT -> Unable to fetch media contents for publisher: ${user.userId}`)
        }
    }, [mediaInProductionReq.loading]);

    useEffect(() => {
        if (!!user.userId && podcastsDownloaded) {
            setCartLoading(true)
            currentCartReq.execute()
        }
    }, [podcastsDownloaded])

    useEffect(() => {
        if (!currentCartReq.loading && currentCartReq.data) {
            console.log('cart fetched')
            updateCart(currentCartReq.data)
        }
    }, [currentCartReq.loading])

    const updateCart = (data: any) => {
        let mapping: Map<string, PodcastCartItem> = new Map<string, PodcastCartItem>()
        let newCart: PodcastCartItem[] = []
        data.forEach((cartItem: PodcastCartItem) => {
            mapping.set(cartItem.episode.id as string, cartItem)
            if (cartItem.status === PaymentStatus.IN_CART) {
                newCart.push(cartItem)
            }
        })
        setCartMap(mapping)
        setCartDownloaded(true)
        setCartLoading(false)
        setCart(newCart)
    }

    useEffect(() => {
        if (!fetchRssReq.loading && fetchRssReq.data) {
            setRssLink('')
            setFetchRss(false)
            setRssLoading(false)
            window.location.reload()
        } else if (fetchRssReq.error) {
            console.log(`Error fetching RSS feed: ${fetchRssReq.error}`)
            setRssLink('')
            setFetchRss(false)
            setRssLoading(false)
        }
    }, [fetchRssReq.loading])

    const isEpisodeInCart = (episodeId: string) => {
        return cartMap.has(episodeId)
    }

    return (
        <LibraryContext.Provider value = {{
            podcastLoading: podcastLoading,
            episodeLoading: episodesLoading,
            rssLoading: rssLoading,
            podcastsDownloaded: podcastsDownloaded,
            episodesDownloaded: episodesDownloaded,
            languagesProgress: languagesProgress,
            episodeTranscriptionProgress: episodeTranscriptionProgress,
            fetchRssReq: fetchRssReq,
            podcasts: podcasts,
            episodes: episodes,
            episodeInReview: episodeInReview,
            reviewEpisodesLoading: reviewEpisodesLoading,
            mediaInProduction: mediaInProduction,
            mediaInProductionLoading: mediaInProductionLoading,
            mediaInProductionDownloaded: mediaInProductionDownloaded,
            localizedEpisodes: localizedEpisodes,
            podcastEpisodes: podcastEpisodes,
            cart: cart,
            cartMap: cartMap,
            setCart: setCart,
            setCartMap: setCartMap,
            setCartLoading: setCartLoading,
            cartLoading: cartLoading,
            cartDownloaded: cartDownloaded,
            setCartDownloaded: setCartDownloaded,
            setDownloadPodcast: setDownloadPodcast,
            updateCart: updateCart,
            isEpisodeInCart: isEpisodeInCart,
            setNavBarEpisodeTitle: setNavBarEpisodeTitle,
            navBarEpisodeTitle: navBarEpisodeTitle,
            overrideNavBarEpisodeTitle: overrideNavBarEpisodeTitle,
            setOverrideNavBarEpisodeTitle: setOverrideNavBarEpisodeTitle,
            setRssLink: (link: string, publisher: any, podcastId: any) => {
                if (link !== '') {
                    fetchRssReq.setPayloadData({rss_feed: link, publisher: publisher, podcastId: podcastId})
                    setRssLoading(true)
                }
            },
            setFetchRss: setFetchRss,
        }} {...props} />
    )
}
