import {Flex, Skeleton, useDisclosure} from "@chakra-ui/react";
import {useAuth} from "../context/AuthContext";
import React from "react";

export const ShowCardSkeleton = () => {
    const auth: any = useAuth()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Flex onClick={() => {onOpen()}} w={'fit-content'} h={'320px'} p="12px" flexDirection="column" alignItems="center" bgColor={'#fff'} borderRadius="12px" gap={'8px'}>
            <Skeleton w={'500px'} h={'25px'} borderRadius="8px" bgColor="gray.100"/>
            <Skeleton w={'500px'} h={'24px'} borderRadius="6px" bgColor="gray.100"/>
            <Skeleton w={'500px'} h={'36px'} borderRadius="6px" bgColor="gray.100"/>
        </Flex>
    )
}
