import {Flex} from "@chakra-ui/react";
import {Link, useParams} from "react-router-dom";
import {TranscriptCompleteEditor} from "../components/transcript-editors/transcript-complete-page";
import {useState} from "react";
import {TranscriptMode} from "../models/Transcript";
import {TranscriptReviewEditor} from "../components/transcript-editors/review-page";


export default function TranscriptReader({mode}: {mode: TranscriptMode}) {
    const [loading, setLoading] = useState<boolean>(true);
    const { sessionId, podcastId, episodeId }: any = useParams();


    return (
        <Flex w={'full'} p={'4px'} direction={'column'} gap={'16px'} alignItems={'flex-start'}>
            {(mode === TranscriptMode.ASR ||
                mode === TranscriptMode.PROOFREAD ||
                mode === TranscriptMode.COMPLETED
            ) && <TranscriptCompleteEditor mode={mode} />}
            {mode === TranscriptMode.REVIEWING && <TranscriptReviewEditor />}
        </Flex>
    );
};
