import {useUser} from "../../context/UserContext";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Button,
    Flex,
    Group,
    Image,
    Loader,
    Paper,
    Pill,
    PillsInput,
    Popover,
    SegmentedControl,
    Text
} from "@mantine/core";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {useAddTagToEpisodes, useEpisodes, useRemoveTagFromEpisode} from "../../hooks/usePodcastLibraryQueries";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {randomColor} from "@chakra-ui/theme-tools";
import {ConfigurationModule} from "../../components/episode/configuration-module";
import {useLibraryNavigation} from "../../context/LibraryNavigationContext";
import {useGetTranscriptionSessions} from "../../hooks/useLocalizationQueries";
import {PodcastTranscriptionTaskMeta, TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {ProofreadTranscriptProcessor} from "../../models/Transcription";
import {useDisclosure} from "@mantine/hooks";
import {useToast} from "@chakra-ui/react";
import ModifiedPopoverPill from "../../components/episode/modified-popover-pill";

export default function PodcastEpisodeLibrarySidebar () {
    const user: any = useUser()
    const libraryNavigation: any = useLibraryNavigation()
    const {episodeId, podcastId} = useParams()
    const navigate = useNavigate()
    const { data: podcastEpisodesData, isLoading: episodesLoading, error: episodeError } = useEpisodes(podcastId as string);
    const [episode, setEpisode] = React.useState<PodcastEpisode>();
    const [activeTab, setActiveTab] = useState('overview');
    const {data: transcriptionSessions, isLoading: isLoadingTranscriptionSession, error: loadingError} = useGetTranscriptionSessions(podcastId as string)
    const [session, setSession] = useState<TranscriptionSession | null>(null);
    const [opened, { close, open }] = useDisclosure(false);
    const [tags, setTags] = useState<string[]>([]);
    const toast = useToast()
    const { mutate: removeTagFromEpisodeReq, isSuccess: isRemoveTagFromEpisodeSuccess,
        isError: isRemoveTagToEpisodeError, status: removeTagFromEpisodesStatus
    } = useRemoveTagFromEpisode();


    useEffect(() => {
        if (!episodesLoading && podcastEpisodesData && podcastEpisodesData.length > 0) {
            const episode = podcastEpisodesData.find((ep: any) => ep.id === episodeId);

            let epTags: any[] = episode?.tags || [];
            if (episode?.transcription.processor) {
                if (epTags.length > 0) {
                    epTags.push(episode.transcription.processor)
                } else {
                    epTags.push(episode.transcription.processor)
                }
            }
            setEpisode(episode);
            setTags(epTags);
        }
    }, [episodesLoading, podcastEpisodesData, episodeId]);


    useEffect(() => {
        if (episode && episode.transcription.state !== TranscriptionState.NOT_CREATED) {
            if (!isLoadingTranscriptionSession && transcriptionSessions.length > 0) {
                const session = transcriptionSessions.find((session: any) => session.episode.id === episode.id);
                setSession(session);
            } else {
            }
        }
    }, [isLoadingTranscriptionSession, transcriptionSessions, episodeId]);

    useEffect(() => {
        if (activeTab === 'configuration') {
            libraryNavigation.setDisplaySidebar(true)
        } else {
            libraryNavigation.setDisplaySidebar(false)
        }
    }, [activeTab]);

    useEffect(() => {
        if (isRemoveTagFromEpisodeSuccess) {
            toast({
                title: 'Tag removed',
                description: 'Tag removed from episode',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

            close()
        }

        if (isRemoveTagToEpisodeError) {
            toast({
                title: 'Error',
                description: 'Error removing tag from episode',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
    }, [isRemoveTagFromEpisodeSuccess, isRemoveTagToEpisodeError]);

    const handleRemoveTag = (tag: string) => {
        removeTagFromEpisodeReq({
            episodeId: episodeId as string,
            tag: tag
        })
    }

    const getNavigationText = () => {
        if (session) {
            switch (session.state) {
                case TranscriptionState.SPEECH_TO_TEXT: {
                    break
                }
                case TranscriptionState.PROOFREADING: {
                    return 'Open AI Transcript'
                }
                case TranscriptionState.PROOFREADING_COMPLETED: {
                    return 'Open Proofread Transcript'
                }

                case TranscriptionState.READY_FOR_PROOFREADING: {
                    return 'Open AI Transcript'

                }
                case TranscriptionState.EDITOR_REVIEWING: {
                    return 'Open Proofread Transcript'
                }
                case TranscriptionState.EDITOR_REVIEW: {
                    return 'Open Proofread Transcript'
                }

                case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                    return 'Start Final Review'
                }
                case TranscriptionState.PUBLISHER_REVIEWING: {
                    return 'Resume Final Review'
                }
                case TranscriptionState.COMPLETED: {
                    return 'Open Final Transcript'
                }

            }
        }
    }

    const navigateToStudio = () => {
        if (session) {
            libraryNavigation.setStudioSession(session)

            switch (session.state) {
                case TranscriptionState.SPEECH_TO_TEXT: {
                    break
                }
                case TranscriptionState.PROOFREADING: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
                case TranscriptionState.PROOFREADING_COMPLETED: {
                    navigate(`/transcription/${session.id}`)
                    break
                }

                case TranscriptionState.READY_FOR_PROOFREADING: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
                case TranscriptionState.EDITOR_REVIEWING: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
                case TranscriptionState.EDITOR_REVIEW: {
                    navigate(`/transcription/${session.id}`)
                    break
                }

                case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                    navigate(`/review/${session.id}/${(session as PodcastTranscriptionTaskMeta).podcast.id}/${(session as PodcastTranscriptionTaskMeta).episode.id}`)
                    break
                }
                case TranscriptionState.PUBLISHER_REVIEWING: {
                    navigate(`/review/${session.id}/${(session as PodcastTranscriptionTaskMeta).podcast.id}/${(session as PodcastTranscriptionTaskMeta).episode.id}`)
                    break
                }
                case TranscriptionState.COMPLETED: {
                    navigate(`/transcription/${session.id}`)
                    break
                }
            }
        }
    }

    return (
        <Flex w={'100%'} h={'100%'} direction="column" style={{ alignSelf: 'stretch' }}>
            <Flex w="100%" direction="row" align="center" justify="flex-start" gap="md">
                <ChevronLeftIcon w="24px" h="24px" onClick={() => navigate(`/library/${podcastId}`)} cursor="pointer"/>
            </Flex>
            {episode && (
                <Flex w={'100%'} h={'100%'} direction="column" style={{ alignSelf: 'stretch', overflow: 'hidden' }}>
                    <SegmentedControl
                        w={'100%'}
                        value={activeTab}
                        color={'#319795'}
                        size={'md'}
                        withItemsBorders
                        transitionDuration={300}
                        transitionTimingFunction="linear"
                        onChange={setActiveTab}
                        data={[
                            { label: 'Overview', value: 'overview' },
                            { label: 'Configuration', value: 'configuration' },
                        ]}
                        styles={{
                            root: {
                                padding: '10px 10px',
                            },
                            label: {
                                fontWeight: 600,
                                fontSize: '14px',
                            }
                        }}
                    />
                    <Flex w={'100%'} direction="column" style={{ flex: 1, overflow: 'auto' }}>
                        {activeTab === 'overview' && (
                            <Flex w={'100%'} direction="column" style={{ height: '100%' }}>
                                <Paper p={'md'} w={'100%'} radius="md">
                                    <Flex align={'flex-start'} gap={'md'} direction={'column'}>
                                        <Image src={episode.image_url || episode.podcast?.image_url} radius="md" />
                                        <Flex direction="column" gap="xs" style={{ flex: '1 0 0' }}>
                                            <Text c="gray.8" fz="md" fw={600}>
                                                {episode.title}
                                            </Text>
                                            <Group gap="xs">
                                                <Flex h={'100%'} align={'center'} w="100%" style={{borderRadius: 'var(--mantine-radius-xl)' }}>
                                                    <PillsInput w={'100%'} size={'md'} variant={'unstyled'}>
                                                        <Pill.Group style={{
                                                            display: 'flex',
                                                            flexWrap: 'nowrap',
                                                            paddingRight: '10px',
                                                            alignItems: 'center',
                                                            textColor: 'var(--mantine-color-gray-6)',
                                                        }}>
                                                            {tags?.map((tag) => (
                                                                <ModifiedPopoverPill
                                                                    key={tag}
                                                                    tag={tag}
                                                                    onRemoveTag={() => {handleRemoveTag(tag)}}
                                                                    removeTagStatus={removeTagFromEpisodesStatus}
                                                                />
                                                            ))}
                                                        </Pill.Group>
                                                    </PillsInput>
                                                </Flex>
                                            </Group>
                                        </Flex>
                                    </Flex>
                                </Paper>
                                <Flex style={{ flex: 1 }} />
                            </Flex>
                        )}
                        {activeTab === 'configuration' && <ConfigurationModule episode={episode} />}
                    </Flex>
                    {(activeTab === 'overview' && session && episode.transcription.processor === ProofreadTranscriptProcessor.BRAINCAP) && <Flex w={'100%'} p={'md'} justify={'center'} style={{ marginTop: 'auto' }}>
                        <Button variant={'outline'} w={'100%'} onClick={navigateToStudio}>{getNavigationText()}</Button>
                    </Flex>}
                </Flex>
            )}
        </Flex>
    )
}
