import {Flex, Group, Paper, SimpleGrid, Text} from '@mantine/core';
import {
    IconUserPlus,
    IconDiscount2,
    IconReceipt2,
    IconCoin,
    IconArrowUpRight,
    IconArrowDownRight, IconEye, IconUsers, IconVideo,
} from '@tabler/icons-react';
import {YoutubeChannelInfo} from "../../models/Youtube";

const icons = {
    user: IconUserPlus,
    discount: IconDiscount2,
    receipt: IconReceipt2,
    coin: IconCoin,
};

export function YoutubeChannelInfoModule({channel}: {channel: YoutubeChannelInfo}) {

    function formatStatsCount(num: number): [string, string] {
        // Format the number with commas
        const formattedNum = num.toLocaleString('en-US');

        // Format the abbreviated version
        let abbreviated: string;
        if (num >= 1000000) {
            abbreviated = (num / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
        } else if (num >= 1000) {
            abbreviated = Math.floor(num / 1000) + 'K';
        } else {
            abbreviated = num.toString();
        }

        return [formattedNum, abbreviated];
    }

    const data = [
        { title: 'Total Views', icon: <IconEye stroke={2} />, value: formatStatsCount(channel.viewCount)[1]},
        { title: 'Subscribers', icon: <IconUsers stroke={2} />, value: formatStatsCount(channel.subscriberCount)[1]},
        { title: 'Videos', icon: <IconVideo stroke={2} />, value: formatStatsCount(channel.videoCount)[1]},
    ] as const

    const stats = data.map((stat) => {
        return (
            <Paper w={'100%'} withBorder p="md" radius="md" key={stat.title}>
                <Group justify="space-between">
                    <Text size="xs" c="dimmed" fw={700}>
                        {stat.title}
                    </Text>
                    {stat.icon}
                </Group>

                <Group align="flex-end" gap="xs" mt={25}>
                    <Text fw={700}>{stat.value}</Text>
                </Group>
            </Paper>
        );
    });
    return (
        <SimpleGrid w={'100%'} cols={3}>{stats}</SimpleGrid>
    );
}
